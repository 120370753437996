import React, { useEffect, useState } from 'react';
import useGlobal from '../../../store';

const Map = () => {
    const [state, setState] = useState({ rosParams: undefined });
    const [globalState] = useGlobal();

    useEffect(() => {
        if (globalState.workers !== undefined && globalState.configWorker !== undefined) {
            var id = -1;
            for (var i = 0; i < globalState.workers.length; i++) {
                if (globalState.workers[i]._id === globalState.configWorker) {
                    id = i;
                    break;
                }
            }
            setState({ ...state, rosParams: globalState.workers[id].ros })
            var elem = document.getElementById('nav_div');
            elem.innerHTML = "";
        }
    }, globalState.configWorker)

    useEffect(() => {
        if (globalState.configWorker !== undefined) {
            view_map();
        }
    }, [state.rosParams])

    const view_map = () => {
        if (state.rosParams !== undefined) {
            var viewer = new window.ROS2D.Viewer({
                divID: "nav_div",
                width: 640,
                height: 480
            })

            var navClient = new window.NAV2D.OccupancyGridClientNav({
                ros: globalState.ros,
                rootObject: viewer.scene,
                viewer: viewer,
                serverName: state.rosParams.moveBaseTopic,
                withOrientation: true
            });            
        }
    }


    return (
        <div>
            <div id="nav_div"></div>
        </div>
    );
}


export default Map;