import React, { useEffect } from 'react';
import useGlobal from "../../store";
import { WAITING, ACTIVE, RESERVED } from '../../components/statusTypes';
import { workers } from '../../components/dbNames';
import '../../components/style.css';
import { Container, Row, Col } from 'react-bootstrap';
import Connection from './rosComponents/Connection';
import Teleoperation from './rosComponents/Teleoperation';
import RobotStatePose from './rosComponents/RobotStatePose';
import RobotStateVelocity from './rosComponents/RobotStateVelocity';
import Map from './rosComponents/Map';

const ROSControl = () => {
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        globalActions.configureAction('configWorker', undefined);
        globalActions.configureAction('configWorkstation', undefined);
        update();
    }, []);

    const update = () => {
        globalActions.getData(workers, '{}', globalState.token);
    }

    const selectWorker = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configWorker', event.target.id);
            globalActions.configureAction('configWorkstation', undefined);
            globalActions.ros('setROS', new window.ROSLIB.Ros());
        }
    }

    function renderWorker() {
        if (globalState.workers === undefined) {
            return (<div>Loading....</div>)
        } else {
            return (
                <div>
                    <div className="grid">
                        <div className="quality1">
                            <div className="ui message">
                                <h4>
                                    Workers
                                </h4>
                            </div>
                            {globalState.workers.map((wk, index) => {

                                var selected = "poContent"

                                let icon = 'exclamation triangle icon'
                                switch (wk.status) {
                                    case WAITING:
                                        if (wk.jobQueue[0].po !== '') {
                                            selected = "poContent active"
                                        } else {
                                            selected = "poContent"
                                        }
                                        icon = "heart outline icon";
                                        break;
                                    case ACTIVE:
                                        selected = "poContent active"
                                        icon = "cogs icon";
                                        break;
                                    case RESERVED:
                                        selected = "poContent reserved"
                                        icon = "download icon";
                                        break;
                                    default:
                                        selected = "poContent error"
                                        icon = "exclamation triangle icon";
                                        break;
                                }

                                if (globalState.configWorker === wk._id) {
                                    selected += " selected"
                                }

                                return (
                                    <div className={selected} id={wk._id} onClick={selectWorker} >
                                        <div className="header" id={wk._id} onClick={selectWorker}>
                                            <i className={icon}></i>
                                            WS Name: {wk.name}
                                            <div id={wk._id} onClick={selectWorker} >PO in Station: {wk.poInStation[0]}</div>
                                            <div id={wk._id} onClick={selectWorker} >Source: {wk.jobActive[0].source} | Destination: {wk.jobActive[0].destination}</div>
                                        </div>

                                    </div>
                                )
                            })}


                        </div>
                    </div>
                </div>
            )
        }
    }

    const renderTeleoperation = () => {
        if (globalState.configWorker !== undefined) {
            return (
                <div><Teleoperation /></div>
            )
        }
    }

    const renderMap = () => {
        if (globalState.configWorker !== undefined) {
            return (
                <div><Map /></div>
            )
        }
    }

    const renderPose = () => {
        if (globalState.configWorker !== undefined) {
            return (
                <div><RobotStatePose /></div>
            )
        }
    }

    const renderVelocity = () => {
        if (globalState.configWorker !== undefined) {
            return (
                <div><RobotStateVelocity /></div>
            )
        }
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        {renderWorker()}
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Connection />
                            </Col>
                            <Col>
                                {renderTeleoperation()}
                            </Col>
                        </Row>
                        <Row>
                            {renderMap()}
                        </Row>
                        <Row>
                            <Col>
                                {renderPose()}
                            </Col>
                            <Col>
                                {renderVelocity()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ROSControl;
