import React, { useEffect, useState } from 'react';
import '../style.css';
import { workers } from '../dbNames';
import useGlobal from '../../store';
import server from '../../api/server';

const WorkerConfig = () => {
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({
        status: 'Ready to configure worker',
        input: ['', '', ['tmp'], { posX: 0, posY: 0, posZ: 0, orientX: 0, orientY: 0, orientZ: 0, orientW: 1 }, { ipAddress: '127.0.0.1', port: '9090', velTopic: '/cmd_vel', moveBaseTopic: '/move_base', poseTopic: '/amcl_pose', poseMessageType: 'geometry_msgs/PoseWithCovarianceStamped', velocityTopic: '/odom', velocityMessageType: 'nav_msgs/Odometry', reconnectionTimer: 5000 }, 0, 'NONE', 'NONE']
    });
    const allowedUpdates = ['name', 'image', 'workerGroup', 'gridLocation', 'ros', 'workerRate', 'assetCode', 'issuerPublicKey']

    useEffect(() => {
        globalActions.getData(workers, '{}', globalState.token);
        globalActions.configureAction('configWorker', undefined)
    }, []);

    const selectWorker = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configWorker', event.target.id);
            var ws = '';
            for (var i = 0; i < globalState.workers.length; i++) {
                if (globalState.workers[i]._id === event.target.id) {
                    ws = globalState.workers[i];
                    break;
                }
            }
            var array = state.input
            allowedUpdates.map((field, index) => {
                array[index] = ws[field]
            })
            setState({ ...state, status: 'Ready to configure worker', input: array })
        }
    }

    const newWorker = (event) => {
        if (event !== undefined) {
            setState({ ...state, input: ['**name**', 'default.jpg', ['PO Movement'], { posX: 0, posY: 0, posZ: 0, orientX: 0, orientY: 0, orientZ: 0, orientW: 1 }, { ipAddress: '127.0.0.1', port: '9090', velTopic: '/cmd_vel', moveBaseTopic: '/move_base', poseTopic: '/amcl_pose', poseMessageType: 'geometry_msgs/PoseWithCovarianceStamped', velocityTopic: '/odom', velocityMessageType: 'nav_msgs/Odometry', reconnectionTimer: 5000 }, 0, 'NONE', 'NONE'] })
            globalActions.configureAction('configWorker', event.target.id)
        }
    }

    function renderStatus() {
        if (globalState.workers === undefined) {
            return (<p>Return to home</p>)
        } else {
            return (<p>{state.status}</p>);
        }
    }

    const submitChanges = (event) => {
        if (event !== undefined) {
            var z = document.getElementById("frm1")
            var updateObject = {}
            for (var i = 0; i < z.length; i++) {
                if (z.elements[i].name === 'posX') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "posX": z.elements[i].value }
                } else if (z.elements[i].name === 'posY') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "posY": z.elements[i].value }
                } else if (z.elements[i].name === 'posZ') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "posZ": z.elements[i].value }
                } else if (z.elements[i].name === 'orientX') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "orientX": z.elements[i].value }
                } else if (z.elements[i].name === 'orientY') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "orientY": z.elements[i].value }
                } else if (z.elements[i].name === 'orientZ') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "orientZ": z.elements[i].value }
                } else if (z.elements[i].name === 'orientW') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "orientW": z.elements[i].value }
                } else if (z.elements[i].name === 'ipAddress') {
                    updateObject['ros'] = { ...updateObject['ros'], "ipAddress": z.elements[i].value }
                } else if (z.elements[i].name === 'port') {
                    updateObject['ros'] = { ...updateObject['ros'], "port": z.elements[i].value }
                } else if (z.elements[i].name === 'velTopic') {
                    updateObject['ros'] = { ...updateObject['ros'], "velTopic": z.elements[i].value }
                } else if (z.elements[i].name === 'moveBaseTopic') {
                    updateObject['ros'] = { ...updateObject['ros'], "moveBaseTopic": z.elements[i].value }
                } else if (z.elements[i].name === 'poseTopic') {
                    updateObject['ros'] = { ...updateObject['ros'], "poseTopic": z.elements[i].value }
                } else if (z.elements[i].name === 'poseMessageType') {
                    updateObject['ros'] = { ...updateObject['ros'], "poseMessageType": z.elements[i].value }
                } else if (z.elements[i].name === 'velocityTopic') {
                    updateObject['ros'] = { ...updateObject['ros'], "velocityTopic": z.elements[i].value }
                } else if (z.elements[i].name === 'velocityMessageType') {
                    updateObject['ros'] = { ...updateObject['ros'], "velocityMessageType": z.elements[i].value }
                } else if (z.elements[i].name === 'reconnectionTimer') {
                    updateObject['ros'] = { ...updateObject['ros'], "reconnectionTimer": z.elements[i].value }
                } else {
                    if (z.elements[i].value.indexOf(',') === -1) {
                        updateObject[z.elements[i].name] = z.elements[i].value
                    } else {
                        updateObject[z.elements[i].name] = z.elements[i].value.split(',')
                    }
                }
            }
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            if (globalState.configWorker === 'new') {
                server.post(`/workers`, updateObject, config)
                    .then((response) => {
                        if (response.status === 201) {
                            setState({ ...state, status: 'Worker successfully created' });
                            globalActions.getData(workers, '{}', globalState.token);
                            globalActions.configureAction('configWorker', response.data._id)
                        } else {
                            setState({ ...state, status: `Their was an error in worker update, check data entered` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        console.log(errorCode)
                        switch (errorCode) {
                            case 401:
                                errorCode += ' There is a problem with the Asset Code or Issuer Public Key'
                                break;
                            case 402:
                                errorCode += ' Asset does not have an owner or there is more than one owner'
                                break;
                            case 403:
                                errorCode += ' Asset owner does not have a trustline for the currency'
                                break;
                            case 404:
                                errorCode += ' Name has a space or special character that is not allowed'
                                break;
                            default:
                                errorCode += ' Unknown error'
                                break;
                        }
                        var message = `Problem saving worker error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            } else {
                server.patch(`/workers/${globalState.configWorker}`, updateObject, config)
                    .then((response) => {
                        if (response.status === 200) {
                            setState({ ...state, status: 'Worker successfully updated' });
                            globalActions.getData(workers, '{}', globalState.token);
                        } else {
                            setState({ ...state, status: `Their was an error in worker update, check data entered` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        switch (errorCode) {
                            case 401:
                                errorCode += ' There is a problem with the Asset Code or Issuer Public Key'
                                break;
                            case 402:
                                errorCode += ' Asset does not have an owner or there is more than one owner'
                                break;
                            case 403:
                                errorCode += ' Asset owner does not have a trustline for the currency'
                                break;
                            case 404:
                                errorCode += ' Name has a space or special character that is not allowed'
                                break;
                            default:
                                errorCode += ' Unknown error'
                                break;
                        }
                        var message = `Problem saving worker error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            }
        }
    }

    function deleteWorker(event) {
        if (event !== undefined) {
            var confirmation = window.confirm('Are you sure you want to delete?')
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            if (confirmation === true) {
                server.delete(`/workers/${globalState.configWorker}`, config)
                    .then((response) => {
                        if (response.status === 200) {
                            setState({ ...state, status: 'Worker successfully deleted' });
                            globalActions.getData(workers, '{}', globalState.token);
                            globalActions.configureAction('configWorker', undefined)
                        } else {
                            setState({ ...state, status: `Their was an error deleting the worker` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        var message = `Problem deleting worker error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            }
        }
    }

    function renderDeleteButton() {
        if (globalState.configWorker !== 'new') {
            return (
                <div className="button" id="delete" onClick={deleteWorker}>
                    DELETE
                </div>
            )
        } else {
            return <div></div>
        }
    }

    function renderWorkers() {
        if (globalState.workers === undefined) {
            return (<div>Loading....</div>)
        } else {

            return (
                <div>
                    <div className="ui message">
                        <h2>
                            Worker Configuration
                            <div className="button" id="new" onClick={newWorker}>
                                NEW
                            </div>
                        </h2>

                    </div>
                    <div className="grid">
                        <div className="quality1">
                            {globalState.workers.map(ws => {
                                var selected = "poContent"
                                if (ws._id === globalState.configWorker) {
                                    selected = "poContent selected";
                                }
                                return (
                                    <div class={selected} id={ws._id} onClick={selectWorker} >
                                        <div className="header" id={ws._id}>
                                            <i className="industry icon" id={ws._id}></i>
                                            Name: {ws.name}
                                        </div>
                                        <div className="header" id={ws._id}>
                                            <i className="qrcode icon" id={ws._id}></i>
                                            ID: {ws._id}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="editWindow">{renderEditWindow()}</div>
                    </div>
                </div>
            )
        }
    }

    function renderEditWindow() {
        if (globalState.configWorker === undefined) {
            return (<div>Please select a worker to configure or New to create a new worker</div>)
        } else {
            var ws = {}
            for (var i = 0; i < globalState.workers.length; i++) {
                if (globalState.workers[i]._id === globalState.configWorker) {
                    ws = globalState.workers[i];
                    break;
                }
            }
            return (
                <div>
                    <div>
                        <div className="spacing">_id: {globalState.configWorker}</div>
                        <form id="frm1">
                            {allowedUpdates.map((field, index) => {
                                var value = undefined
                                var type = typeof state.input[index]

                                switch (type) {
                                    case 'string':
                                        value = ws[field]
                                        break;
                                    case 'object':
                                        if (Array.isArray(state.input[index])) {
                                            // HERE WE NEED TO HANDLE IF THERE IS MORE THAN ONE ELEMENT IN THE ARRAY
                                            value = state.input[index]
                                        } else {
                                            value = 'object'
                                        }
                                        break;
                                    case 'number':
                                        value = state.input[index]
                                        break;
                                    default:
                                        break;
                                }

                                if (value === 'object') {
                                    const names = Object.keys(state.input[index])
                                    return (
                                        <div>
                                            <div className="spacing">{field}:</div>
                                            {names.map(value => {
                                                return (
                                                    <div>
                                                        <div className="spacing">
                                                            {value}:<input
                                                                className="input small"
                                                                type={typeof state.input[index][value]}
                                                                name={value}
                                                                value={state.input[index][value]}
                                                                onChange={e => {
                                                                    var array = state.input
                                                                    if (typeof state.input[index][value] === 'number') {
                                                                        array[index][value] = parseFloat(e.target.value);
                                                                    } else {
                                                                        array[index][value] = e.target.value;
                                                                    }
                                                                    setState({ ...state, input: array })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="spacing">
                                            {field}:
                                            <input
                                                className="input"
                                                type={type}
                                                name={field}
                                                value={state.input[index]}
                                                onChange={e => {
                                                    var array = state.input
                                                    array[index] = e.target.value
                                                    setState({ ...state, input: array })
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            })}
                        </form>
                        <div className="button" id="save" onClick={submitChanges}>
                            SAVE
                        </div>
                        {renderDeleteButton()}
                    </div>
                    <div>Enter multiple worker groups using comma's</div>
                    <div className="ui message">
                        <div className="header">
                            Status:
                        </div>
                        {renderStatus()}
                    </div>
                </div>
            )
        }
    }


    return (
        < div >
            {renderWorkers()}
        </div >
    )

};

export default WorkerConfig;