import React, { useEffect, useState } from 'react';
import useGlobal from '../../store';
import { Scatter } from 'react-chartjs-2'
import { workers, workstations, settings } from '../dbNames';
import '../style.css';

const MonitorGrid = () => {
    const [globalState, globalActions] = useGlobal();
    useEffect(() => {
        document.title = 'AGM Monitor'
        globalActions.getData(workers, '{}', globalState.token);
        globalActions.getData(workstations, '{}', globalState.token);
        globalActions.getData(settings, '{}', globalState.token);
    }, []);

    const [state] = useState({
        data: {
            labels: ['Scatter'],
            datasets: [
                {
                    label: [],
                    fill: false,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 3,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 8,
                    pointHitRadius: 10,
                    data: []
                },
                {
                    label: [],
                    fill: false,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    pointBorderColor: 'rgba(255,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 3,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 12,
                    pointHitRadius: 10,
                    data: []
                }
            ]
        },
        options: {
            tooltips: {
                mode: 'point',
                callbacks: {
                    title: function (tooltipItems, data) {
                        //Return value for title
                        return data.datasets[tooltipItems[0].datasetIndex].label[tooltipItems[0].index]
                    }
                }
            },
            scales: {
                xAxes: [{
                    type: 'linear',
                    position: 'bottom',
                    ticks: {
                        beginAtZero: true,
                        max: 20,
                        min: 0
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        max: 20,
                        min: 0
                    }
                }]
            },
            layout: {
                padding: {
                    left: 50,
                    right: 50,
                    top: 0,
                    bottom: 0
                }
            }
        }
    });

    function renderScatter() {
        if (globalState.workers !== undefined && globalState.workstations !== undefined && globalState.settings !== undefined) {
            var workerData = [];
            var workerLabel = [];
            for (var i = 0; i < globalState.workers.length; i++) {
                workerData.push({ x: globalState.workers[i].gridLocation.posX, y: globalState.workers[i].gridLocation.posY })
                workerLabel.push(globalState.workers[i].name);
            }
            var workstationData = [];
            var workstationLabel = [];
            for (i = 0; i < globalState.workstations.length; i++) {
                workstationData.push({ x: globalState.workstations[i].gridLocation.posX, y: globalState.workstations[i].gridLocation.posY })
                workstationLabel.push(globalState.workstations[i].name);
            }

            //worker data            
            state.data.datasets[0].data = workerData;
            state.data.datasets[0].label = workerLabel;

            //workstation data
            state.data.datasets[1].data = workstationData;
            state.data.datasets[1].label = workstationLabel;

            //setup grid boundaries
            state.options.scales.xAxes[0].ticks.max = globalState.settings.monitorGrid.endX;
            state.options.scales.xAxes[0].ticks.min = globalState.settings.monitorGrid.startX;
            state.options.scales.yAxes[0].ticks.max = globalState.settings.monitorGrid.endY;
            state.options.scales.yAxes[0].ticks.min = globalState.settings.monitorGrid.startY;

            return (
                <Scatter
                    data={state.data}
                    options={state.options}
                />
            );
        } else {
            return <div>Loading....</div>
        }

    }

    return (
        <div className="graphWindow">
            {renderScatter()}
        </div>
    )

}

export default MonitorGrid;