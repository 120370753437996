import React, { useEffect, useState } from 'react';
import '../style.css';
import { workstations } from '../dbNames';
import useGlobal from '../../store';
import server from '../../api/server'

const WorkstationConfig = () => {
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({
        status: 'Ready to configure workstation',
        input: ['', '', ['tmp'], { posX: 0, posY: 0, posZ: 0, orientX: 0, orientY: 0, orientZ: 0, orientW: 1 }, 0, 0, 'NONE', 'NONE']
    });
    const allowedUpdates = ['name', 'image', 'workstationGroup', 'gridLocation', 'cyclesToCertify', 'workstationRate', 'assetCode', 'issuerPublicKey']

    useEffect(() => {
        globalActions.getData(workstations, '{}', globalState.token);
        globalActions.configureAction('configWorkstation', undefined)
    }, []);

    const selectWorkstation = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configWorkstation', event.target.id);
            var ws = '';
            for (var i = 0; i < globalState.workstations.length; i++) {
                if (globalState.workstations[i]._id === event.target.id) {
                    ws = globalState.workstations[i];
                    break;
                }
            }
            var array = state.input
            allowedUpdates.map((field, index) => {
                array[index] = ws[field]
            })
            setState({ ...state, status: 'Ready to configure workstation', input: array })
        }
    }

    const newWorkstation = (event) => {
        if (event !== undefined) {
            setState({ ...state, input: ['**name**', 'default.jpg', ['**workstation group**'], { posX: 0, posY: 0, posZ: 0, orientX: 0, orientY: 0, orientZ: 0, orientW: 1 }, -1, 0, 'NONE', 'NONE'] })
            globalActions.configureAction('configWorkstation', event.target.id)
        }
    }

    function renderStatus() {
        if (globalState.workstations === undefined) {
            return (<p>Return to home</p>)
        } else {
            return (<p>{state.status}</p>);
        }
    }

    const submitChanges = (event) => {
        if (event !== undefined) {
            var z = document.getElementById("frm1")
            var updateObject = {}
            for (var i = 0; i < z.length; i++) {
                if (z.elements[i].name === 'posX') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "posX": z.elements[i].value }
                } else if (z.elements[i].name === 'posY') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "posY": z.elements[i].value }
                } else if (z.elements[i].name === 'posZ') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "posZ": z.elements[i].value }
                } else if (z.elements[i].name === 'orientX') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "orientX": z.elements[i].value }
                } else if (z.elements[i].name === 'orientY') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "orientY": z.elements[i].value }
                } else if (z.elements[i].name === 'orientZ') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "orientZ": z.elements[i].value }
                } else if (z.elements[i].name === 'orientW') {
                    updateObject['gridLocation'] = { ...updateObject['gridLocation'], "orientW": z.elements[i].value }
                } else {
                    if (z.elements[i].value.indexOf(',') === -1) {
                        updateObject[z.elements[i].name] = z.elements[i].value
                    } else {
                        var array = z.elements[i].value.split(',')
                        updateObject[z.elements[i].name] = z.elements[i].value.split(',')
                    }
                }
            }
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            if (globalState.configWorkstation === 'new') {
                server.post(`/workstations`, updateObject, config)
                    .then((response) => {
                        if (response.status === 201) {
                            setState({ ...state, status: 'Workstation successfully created' });
                            globalActions.getData(workstations, '{}', globalState.token);
                            globalActions.configureAction('configWorkstation', response.data._id)
                        } else {
                            setState({ ...state, status: `Their was an error in workstation update, check data entered` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        switch (errorCode) {
                            case 401:
                                errorCode += ' There is a problem with the Asset Code or Issuer Public Key'
                                break;
                            case 402:
                                errorCode += ' Asset does not have an owner or there is more than one owner'
                                break;
                            case 403:
                                errorCode += ' Asset owner does not have a trustline for the currency'
                                break;
                            default:
                                errorCode += ' Unknown error'
                                break;
                        }
                        var message = `Problem saving workstation error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            } else {
                server.patch(`/workstations/${globalState.configWorkstation}`, updateObject, config)
                    .then((response) => {
                        if (response.status === 200) {
                            setState({ ...state, status: 'Workstation successfully updated' });
                            globalActions.getData(workstations, '{}', globalState.token);
                        } else {
                            setState({ ...state, status: `Their was an error in workstation update, check data entered` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        switch (errorCode) {
                            case 401:
                                errorCode += ' There is a problem with the Asset Code or Issuer Public Key'
                                break;
                            case 402:
                                errorCode += ' Asset does not have an owner or there is more than one owner'
                                break;
                            case 403:
                                errorCode += ' Asset owner does not have a trustline for the currency'
                                break;
                            default:
                                errorCode += ' Unknown error'
                                break;
                        }
                        var message = `Problem saving workstation error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            }
        }
    }

    function deleteWorkstation(event) {
        if (event !== undefined) {
            var confirmation = window.confirm('Are you sure you want to delete?')
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            if (confirmation === true) {
                server.delete(`/workstations/${globalState.configWorkstation}`, config)
                    .then((response) => {
                        if (response.status === 200) {
                            setState({ ...state, status: 'Workstation successfully deleted' });
                            globalActions.getData(workstations, '{}', globalState.token);
                            globalActions.configureAction('configWorkstation', undefined)
                        } else {
                            setState({ ...state, status: `Their was an error deleting the workstation` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        var message = `Problem deleting workstation error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            }
        }
    }

    function renderDeleteButton() {
        if (globalState.configWorkstation !== 'new') {
            return (
                <div className="button" id="delete" onClick={deleteWorkstation}>
                    DELETE
                </div>
            )
        } else {
            return <div></div>
        }
    }

    function renderWorkstations() {
        if (globalState.workstations === undefined) {
            return (<div>Loading....</div>)
        } else {

            return (
                <div>
                    <div className="ui message">
                        <h2>
                            Workstation Configuration
                            <div className="button" id="new" onClick={newWorkstation}>
                                NEW
                            </div>
                        </h2>

                    </div>
                    <div className="grid">
                        <div className="quality1">
                            {globalState.workstations.map(ws => {
                                var selected = "poContent"
                                if (ws._id === globalState.configWorkstation) {
                                    selected = "poContent selected";
                                }
                                return (
                                    <div className={selected} id={ws._id} onClick={selectWorkstation} >
                                        <div className="header" id={ws._id}>
                                            <i className="industry icon" id={ws._id}></i>
                                            Name: {ws.name}
                                        </div>
                                        <div className="header" id={ws._id}>
                                            <i className="qrcode icon" id={ws._id}></i>
                                            ID: {ws._id}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="editWindow">{renderEditWindow()}</div>
                    </div>
                </div>
            )
        }
    }

    function renderEditWindow() {
        if (globalState.configWorkstation === undefined) {
            return (<div>Please select a workstation to configure or New to create a new workstation</div>)
        } else {
            var ws = {}
            for (var i = 0; i < globalState.workstations.length; i++) {
                if (globalState.workstations[i]._id === globalState.configWorkstation) {
                    ws = globalState.workstations[i];
                    break;
                }
            }
            return (
                <div>
                    <div>
                        <div className="spacing">_id: {globalState.configWorkstation}</div>
                        <form id="frm1">

                            {allowedUpdates.map((field, index) => {
                                var value = undefined
                                var type = typeof state.input[index]

                                switch (type) {
                                    case 'string':
                                        value = ws[field]
                                        break;
                                    case 'object':
                                        if (Array.isArray(state.input[index])) {
                                            // HERE WE NEED TO HANDLE IF THERE IS MORE THAN ONE ELEMENT IN THE ARRAY
                                            value = state.input[index]
                                        } else {
                                            value = 'object'
                                        }
                                        break;
                                    case 'number':
                                        value = state.input[index]
                                        break;
                                    default:
                                        break;
                                }

                                if (value === 'object') {
                                    const names = Object.keys(state.input[index])
                                    return (
                                        <div>
                                            <div className="spacing">{field}:</div>
                                            <div>
                                                <div className="spacing">
                                                    posX:<input
                                                        className="input small"
                                                        type={typeof state.input[index].posX}
                                                        name={names[0]}
                                                        value={state.input[index].posX}
                                                        onChange={e => {
                                                            var array = state.input
                                                            array[index] = {
                                                                posX: e.target.value, posY: state.input[index].posY, posZ: state.input[index].posZ,
                                                                orientX: state.input[index].orientX, orientY: state.input[index].orientY, orientZ: state.input[index].orientZ, orientW: state.input[index].orientW
                                                            }
                                                            setState({ ...state, input: array })
                                                        }}
                                                    />
                                                    posY:<input
                                                        className="input small"
                                                        type={typeof state.input[index].posY}
                                                        name={names[1]}
                                                        value={state.input[index].posY}
                                                        onChange={e => {
                                                            var array = state.input
                                                            array[index] = {
                                                                posX: state.input[index].posX, posY: e.target.value, posZ: state.input[index].posZ,
                                                                orientX: state.input[index].orientX, orientY: state.input[index].orientY, orientZ: state.input[index].orientZ, orientW: state.input[index].orientW
                                                            }
                                                            setState({ ...state, input: array })
                                                        }}
                                                    />
                                                    posZ:<input
                                                        className="input small"
                                                        type={typeof state.input[index].posZ}
                                                        name={names[2]}
                                                        value={state.input[index].posZ}
                                                        onChange={e => {
                                                            var array = state.input
                                                            array[index] = {
                                                                posX: state.input[index].posX, posY: state.input[index].posY, posZ: e.target.value,
                                                                orientX: state.input[index].orientX, orientY: state.input[index].orientY, orientZ: state.input[index].orientZ, orientW: state.input[index].orientW
                                                            }
                                                            setState({ ...state, input: array })
                                                        }}
                                                    />
                                                </div>
                                                <div className="spacing">
                                                    orientX:<input
                                                        className="input small"
                                                        type={typeof state.input[index].orientX}
                                                        name={names[3]}
                                                        value={state.input[index].orientX}
                                                        onChange={e => {
                                                            var array = state.input
                                                            array[index] = {
                                                                posX: state.input[index].posX, posY: state.input[index].posY, posZ: state.input[index].posZ,
                                                                orientX: e.target.value, orientY: state.input[index].orientY, orientZ: state.input[index].orientZ, orientW: state.input[index].orientW
                                                            }
                                                            setState({ ...state, input: array })
                                                        }}
                                                    />
                                                    orientY:<input
                                                        className="input small"
                                                        type={typeof state.input[index].orientY}
                                                        name={names[4]}
                                                        value={state.input[index].orientY}
                                                        onChange={e => {
                                                            var array = state.input
                                                            array[index] = {
                                                                posX: state.input[index].posX, posY: state.input[index].posY, posZ: state.input[index].posZ,
                                                                orientX: state.input[index].orientX, orientY: e.target.value, orientZ: state.input[index].orientZ, orientW: state.input[index].orientW
                                                            }
                                                            setState({ ...state, input: array })
                                                        }}
                                                    />
                                                    orientZ:<input
                                                        className="input small"
                                                        type={typeof state.input[index].orientZ}
                                                        name={names[5]}
                                                        value={state.input[index].orientZ}
                                                        onChange={e => {
                                                            var array = state.input
                                                            array[index] = {
                                                                posX: state.input[index].posX, posY: state.input[index].posY, posZ: state.input[index].posZ,
                                                                orientX: state.input[index].orientX, orientY: state.input[index].orientY, orientZ: e.target.value, orientW: state.input[index].orientW
                                                            }
                                                            setState({ ...state, input: array })
                                                        }}
                                                    />
                                                    orientW:<input
                                                        className="input small"
                                                        type={typeof state.input[index].orientW}
                                                        name={names[6]}
                                                        value={state.input[index].orientW}
                                                        onChange={e => {
                                                            var array = state.input
                                                            array[index] = {
                                                                posX: state.input[index].posX, posY: state.input[index].posY, posZ: state.input[index].posZ,
                                                                orientX: state.input[index].orientX, orientY: state.input[index].orientY, orientZ: state.input[index].orientZ, orientW: e.target.value
                                                            }
                                                            setState({ ...state, input: array })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="spacing">
                                            {field}:
                                            <input
                                                className="input"
                                                type={type}
                                                name={field}
                                                value={state.input[index]}
                                                onChange={e => {
                                                    var array = state.input
                                                    array[index] = e.target.value
                                                    setState({ ...state, input: array })
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            })}
                        </form>
                        <div className="button" id="save" onClick={submitChanges}>
                            SAVE
                        </div>
                        {renderDeleteButton()}
                    </div>
                    <div>Enter multiple workstation groups using comma's</div>
                    <div className="ui message">
                        <div className="header">
                            Status:
                        </div>
                        {renderStatus()}
                    </div>
                </div>
            )
        }
    }


    return (
        < div >
            {renderWorkstations()}

        </div >
    )

};

export default WorkstationConfig;