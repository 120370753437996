import React, { useEffect, useState } from 'react';
import WorkerScreen from './WorkerScreen'
import WorkstationScreen from './WorkstationScreen'
import ActiveManufacturing from './ActiveManufacturing';
import ROSControl from './ROSControl';


const ConfigurationMenu = () => {

    const [state, setState] = useState({
        screenSelected: 'Workers'
    });

    const selectScreen = (event) => {
        if (event) {
            setState({ screenSelected: event.target.id })
        }
    }

    const renderConfig = () => {
        if (state.screenSelected === 'Workstations') {
            return (
                <div>
                    <WorkstationScreen id="0" />
                </div>
            )
        } else if (state.screenSelected === 'Workers') {
            return (
                <div>
                    <WorkerScreen id="0" />
                </div>
            )
        } else if (state.screenSelected === 'ActiveManufacturing') {
            return (
                <div>                    
                    <ActiveManufacturing />
                </div>
            )        
        } else if (state.screenSelected === 'ROSControl') {
            return (
                <div>                    
                    <ROSControl />
                </div>
            )        
        } else {
            return <div>Loading....</div>
        }
    }

    return (
        <div>
            <div class="ui secondary menu mainMenuBorder">
                <a class="item" id="Workers" onClick={selectScreen}>
                    Worker Activity
                </a>

                <a class="item" id="Workstations" onClick={selectScreen}>
                    Workstation Activity
                </a>
                <a class="item" id="ActiveManufacturing" onClick={selectScreen}>
                    Active Manufacturing
                 </a>                              
            </div>
            {renderConfig()}

        </div>

    )
}
/*
removed this from the menu while ros control is not important in the context of QBii

<a class="item" id="ROSControl" onClick={selectScreen}>
ROS Worker Control
</a>
*/

export default ConfigurationMenu;