import React, { useEffect, useState } from 'react';
import useGlobal from '../../store';
import { string } from 'prop-types';
import '../style.css';
import server from '../../api/server'
import history from '../../history';

const NewUserScreen = () => {
    const [globalState, globalActions] = useGlobal();

    const [state, setState] = useState({
        status: 'Enter information to create a user profile',
        input: { user: '', password: '', company: '', passwordConfirm: '', network: 'NONE', publicKey: 'NONE', secret: 'NONE', agmPublicKey: 'NONE', agmSecret: 'NONE', currencyAssetCode: 'native', currencyIssuerPublicKey: 'NONE' },
        passwordClass: "input",
        passwordReady: false
    });

    useEffect(() => {
        document.title = 'New User'
        if (globalState.token !== undefined) {
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post(`/users/logout`, {}, config)
                .then((response) => {
                    console.log(response.status)
                    if (response.status === 200) {
                        globalActions.loginAction('login', undefined);
                        localStorage.removeItem('token');
                        globalActions.loginAction('user', undefined);
                        localStorage.removeItem('user');
                        setState({ ...state, status: 'Successfully logged out' });
                    } else {
                        setState({ ...state, status: `Enter information to create a user profile` })
                    }

                }).catch((error) => {
                    var message = `${error}`
                    setState({ ...state, status: message });
                });
        }
    }, []);

    function renderStatus() {
        return (<p>{state.status}</p>);
    }

    function saveUser(event) {
        if (event !== undefined) {

            var z = document.getElementById("frm1")
            var updateObject = {}
            for (var i = 0; i < z.length; i++) {
                if (z.elements[i].value.indexOf(',') === -1) {
                    // we don't want the passwordConfirm in the object
                    if (z.elements[i].name !== 'passwordConfirm') {
                        updateObject[z.elements[i].name] = z.elements[i].value
                    }
                } else {
                    updateObject[z.elements[i].name] = z.elements[i].value.split(',')
                }
            }

            try {
                console.log(updateObject)
                if (updateObject.email === '' || updateObject.password === '' || updateObject.company === '') {
                    const errorMessage = { code: 403, message: 'Enter information in all fields to create a user profile' };
                    throw errorMessage;
                }
                server.post(`/users`, updateObject)
                    .then((response) => {
                        if (response.status === 201) {
                            globalActions.loginAction('login', response.data.token);
                            localStorage.setItem('token', response.data.token);
                            globalActions.loginAction('user', response.data.user);
                            localStorage.setItem('user', response.data.user);
                            setState({ ...state, status: 'Successfully logged in' });
                            history.push('/');
                        } else {
                            setState({ ...state, status: `Their was an error with the username or password` })
                        }
                    }).catch((error) => {
                        var message = '';
                        if (error.toString().includes('404')) {
                            message = `You have entered an invalid Stellar Network`
                        } else if (error.toString().includes('408')) {
                            message = `Problem with your user publicKey and secret combination`
                        } else if (error.toString().includes('406')) {
                            message = `User account is not active on the network`
                        } else if (error.toString().includes('407')) {
                            message = `Problem with your netwokr publicKey and secret combination`
                        } else if (error.toString().includes('405')) {
                            message = `General issue with publicKeys and secrets`
                        } else if (error.toString().includes('409')) {
                            message = `Network account is not active on the network`
                        } else if (error.toString().includes('410')) {
                            message = `Asset does not exist on the Blockchain`
                        } else if (error.toString().includes('411')) {
                            message = `Problem with Asset issuerPublicKey`
                        } else if (error.toString().includes('412')) {
                            message = `Network account does not have a trustline to the currency`
                        } else if (error.toString().includes('413')) {
                            message = `Problems validating network account for asset`
                        } else if (error.toString().includes('414')) {
                            message = `User account does not have a trustline to the currency`
                        } else if (error.toString().includes('415')) {
                            message = `Problems validating user account for asset`
                        } else {
                            message = `Problem creating credentials, check your email and password`
                        }
                        setState({ ...state, status: message });
                    });


            } catch (e) {
                setState({ ...state, status: e });
            }

        }
    }

    return (
        < div className="mainWindow" >
            <form id="frm1">
                <h4>USER INFORMATION </h4>
                <div className="spacing">
                    Name:
                    <input
                        className="input"
                        type={string}
                        name='name'
                        value={state.input.name}
                        onChange={e => {
                            var user = state.input
                            user.name = e.target.value
                            setState({ ...state, input: user })
                        }}
                    />
                </div>
                <div className="spacing">
                    Email:
                    <input
                        className="input"
                        type={string}
                        name='email'
                        value={state.input.email}
                        onChange={e => {
                            var user = state.input
                            user.email = e.target.value
                            setState({ ...state, input: user })
                        }}
                    />
                </div>
                <div className="spacing">
                    Company:
                    <input
                        className="input"
                        type={string}
                        name='company'
                        value={state.input.company}
                        onChange={e => {
                            var user = state.input
                            user.company = e.target.value
                            setState({ ...state, input: user })
                        }}
                    />
                </div>
                <div className="spacing">
                    Password:
                    <input
                        className="input"
                        type="password"
                        name='password'
                        value={state.input.password}
                        onChange={e => {
                            var user = state.input
                            user.password = e.target.value
                            setState({ ...state, input: user })
                        }}
                    />
                    <div className="spacing">
                        Confirm Password:
                        <input
                            className={state.passwordClass}
                            type="password"
                            name='passwordConfirm'
                            value={state.input.passwordConfirm}
                            onChange={e => {
                                var user = state.input
                                user.passwordConfirm = e.target.value
                                setState({ ...state, input: user })
                                if (user.passwordConfirm !== user.password) {
                                    setState({ ...state, passwordClass: "input error", status: 'Passwords do not match', passwordReady: false })
                                } else {
                                    setState({ ...state, passwordClass: "input", status: 'Click submit when ready', passwordReady: true })
                                }
                            }}
                        />
                    </div>
                    <br></br>
                    <h4>STELLAR NETWORK AND ACCOUNTS</h4>
                    <div>Leave as default if not using</div>
                    <div className="spacing">
                        Network:
                        <input
                            className="input"
                            type={string}
                            name='network'
                            value={state.input.network}
                            onChange={e => {
                                var user = state.input
                                user.network = e.target.value
                                setState({ ...state, input: user })
                            }}
                        />
                    </div>
                    <p>Network Options: NONE, TESTNET, PUBLIC</p>
                    <br></br>
                    <h4>USER ACCOUNT</h4>
                    <div className="spacing">
                        User Public Key:
                        <input
                            className="input"
                            type={string}
                            name='publicKey'
                            value={state.input.publicKey}
                            onChange={e => {
                                var user = state.input
                                user.publicKey = e.target.value
                                setState({ ...state, input: user })
                            }}
                        />
                    </div>
                    <div className="spacing">
                        User Secret:
                        <input
                            className="input"
                            type="password"
                            name='secret'
                            value={state.input.secret}
                            onChange={e => {
                                var user = state.input
                                user.secret = e.target.value
                                setState({ ...state, input: user })
                            }}
                        />
                    </div>
                    <br></br>
                    <h4>NETWORK ACCOUNT</h4>
                    <div className="spacing">
                        Network Public Key:
                        <input
                            className="input"
                            type={string}
                            name='agmPublicKey'
                            value={state.input.agmPublicKey}
                            onChange={e => {
                                var user = state.input
                                user.agmPublicKey = e.target.value
                                setState({ ...state, input: user })
                            }}
                        />
                    </div>
                    <div className="spacing">
                        Network Secret:
                        <input
                            className="input"
                            type="password"
                            name='agmSecret'
                            value={state.input.agmSecret}
                            onChange={e => {
                                var user = state.input
                                user.agmSecret = e.target.value
                                setState({ ...state, input: user })
                            }}
                        />
                    </div>
                    <br></br>
                    <h4>NETOWRK CURRENCY</h4>
                    <div className="spacing">
                        Currency Asset Code:
                        <input
                            className="input"
                            type={string}
                            name='currencyAssetCode'
                            value={state.input.currencyAssetCode}
                            onChange={e => {
                                var user = state.input
                                user.currencyAssetCode = e.target.value
                                setState({ ...state, input: user })
                            }}
                        />
                    </div>
                    <div className="spacing">
                        Currency Issuer Public Key:
                        <input
                            className="input"
                            type={string}
                            name='currencyIssuerPublicKey'
                            value={state.input.currencyIssuerPublicKey}
                            onChange={e => {
                                var user = state.input
                                user.currencyIssuerPublicKey = e.target.value
                                setState({ ...state, input: user })
                            }}
                        />
                    </div>
                </div>
            </form>
            <div className="spacing">
                <div className="button" id="login" onClick={saveUser}>
                    SUBMIT
                </div>
            </div>
            {renderStatus()}
        </div >
    )

};

export default NewUserScreen;