import React, { useEffect, useState } from 'react';
import { Joystick } from 'react-joystick-component';
import useGlobal from "../../../store";

const Teleoperation = () => {
    const [state, setState] = useState({ rosParams: undefined });
    const [globalState] = useGlobal();

    useEffect(() => {
        if (globalState.workers !== undefined && globalState.configWorker !== undefined) {
            var id = -1;
            for (var i = 0; i < globalState.workers.length; i++) {
                if (globalState.workers[i]._id === globalState.configWorker) {
                    id = i;
                    break;
                }
            }
            setState({ ...state, rosParams: globalState.workers[id].ros })
        }
    }, globalState.configWorker)

    const handleMove = (event) => {
        if (state.rosParams !== undefined) {
            //we need to create a ROS publisher on the topic /cmd_vel
            var cmd_vel = new window.ROSLIB.Topic({
                ros: globalState.ros,
                name: state.rosParams.velTopic,
                messageType: "geometry_msgs/Twist"
            });
            //we need to create a twist message to be published to rosbridge
            var twist = new window.ROSLIB.Message({
                linear: {
                    x: event.y / 100,
                    y: 0,
                    z: 0
                },
                angular: {
                    x: 0,
                    y: 0,
                    z: -event.x / 50
                }
            });
            //we need to publish the message on the cmd_vel topic
            cmd_vel.publish(twist);
        }
    }
    const handleStop = () => {
        if (state.rosParams !== undefined) {
            //we need to create a ROS publisher on the topic /cmd_vel        
            var cmd_vel = new window.ROSLIB.Topic({
                ros: globalState.ros,
                name: state.rosParams.velTopic,
                messageType: "geometry_msgs/Twist"
            });
            //we need to create a twist message to be published to rosbridge
            var twist = new window.ROSLIB.Message({
                linear: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                angular: {
                    x: 0,
                    y: 0,
                    z: 0
                }
            });
            //we need to publish the message on the cmd_vel topic
            cmd_vel.publish(twist);
        }
    }

    return (
        <div>
            <Joystick
                size={100}
                baseColor="#EEEEEE"
                stickColor="#BBBBBB"
                move={handleMove}
                stop={handleStop}
            ></Joystick>
        </div>
    );
}

export default Teleoperation;