import React, { useEffect } from 'react';
import { workers } from '../components/dbNames';
import useGlobal from '../store';
import ControlMenu from '../components/controlScreenComp/ControlMenu'

const ControlScreen = () => {
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        document.title = 'Control'
        if (globalState.token !== undefined) {
            console.log("UPDATE CONTROL SCREEN")
            globalActions.getData(workers, '{}', globalState.token);
        }
    }, []);


    return (
        < div >
            <ControlMenu />
        </div >
    )

}

export default ControlScreen;