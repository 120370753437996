import React from 'react';
import useGlobal from '../../store';

const StepDetails = props => {
    const [globalState] = useGlobal();

    var aM = undefined;

    for (var i = 0; i < globalState.activeManufacturing.length; i++) {
        if (globalState.activeManufacturing[i].poNum === globalState.qaPO) {
            aM = globalState.activeManufacturing[i];
            break;
        }
    }

    const renderKPV = () => {
        var id = globalState.qaStep;
        if (aM.routing.steps[id].name.includes('Buffer')) {
            return (<div className="ui list"><div className="item">Buffer Station</div></div>)
        } else if (aM.routing.steps[id].status === '') {
            //STEP NOT YET COMPLETE
            return (<div className="ui list"><div className="item">Step Not Complete</div></div>)
        } else {
            var dateStart = new Date(aM.routing.steps[id].kpv.dateTimeStart);
            var dateEnd = new Date(aM.routing.steps[id].kpv.dateTimeEnd);
            return (
                <div className="ui list">
                    <div className="item">
                        Workstation name: {aM.routing.steps[id].kpv.workstationName}
                    </div>
                    <div className="item">
                        Start time: {dateStart.toString()}
                    </div>
                    <div className="item">
                        End time: {dateEnd.toString()}
                    </div>                   
                </div>
            );
        }
    }

    const renderContent = () => {
        if (globalState.qaStep === undefined) {
            return <div>None</div>
        } else {
            var id = globalState.qaStep;
            return (
                <div className="ui card">
                    <div className="content">
                        <div className="header">
                            <i className="folder icon"></i>
                            {aM.routing.steps[id].name}
                            <div>
                                STATUS: {aM.routing.steps[id].status}
                            </div>
                        </div>
                        <div className="description">
                            <div className="ui list">
                            <div className="header">
                                <i className="folder icon"></i>
                                Manufacturing Method:
                            </div>
                            
                                <div className="item">
                                    Workstation Group: {aM.routing.steps[id].workstationGroup}
                                </div>
                                <div className="item">Worker Group:</div>
                                {aM.routing.steps[id].workerGroup.map((workerGroup, index) => (
                                    <div className="item">
                                        <i className="users icon"></i>
                                        {workerGroup}
                                    </div>
                                ))}
                                <div className="item">Tools:</div>
                                {aM.routing.steps[id].tools.map((tools, index) => (
                                    <div className="item">
                                        <i className="wrench icon"></i>
                                        {tools}
                                    </div>
                                ))}
                                <div className="item">Program:</div>
                                {aM.routing.steps[id].program.map((program, index) => (
                                    <div className="item">
                                        <i className="save icon"></i>
                                        {program}
                                    </div>
                                ))}
                                <div className="item">
                                    Assigned Cycle Time: {aM.routing.steps[id].assignedCycleTimeSeconds}
                                </div>

                                <div className="item">
                                    Documentation: {aM.routing.steps[id].documentation}
                                </div>
                            </div>

                            <div className="header">
                                <i className="folder icon"></i>
                                KPV Document
                            </div>

                            {renderKPV()}

                        </div>
                    </div>

                </div>
            );
        }
    }

    return (
        <div>
            {renderContent()}
        </div>
    );
}

export default StepDetails;