import React, { useEffect } from 'react';
import { workstations, workers, activeManufacturing } from '../components/dbNames';
import useGlobal from '../store';
import MonitorGrid from '../components/monitorScreenComp/MonitorGrid'

const MonitorScreen = () => {
    const [globalState, globalActions] = useGlobal();
    
    useEffect(() => {
        document.title = 'Monitor'
        if (globalState.token !== undefined) {
            globalActions.getData(workstations, '{}', globalState.token);
            globalActions.getData(workers, '{}', globalState.token);
            globalActions.getData(activeManufacturing, '{}', globalState.token);
        }
    }, []);


    return (
        < div >          
            <MonitorGrid />
        </div >
    )

}

export default MonitorScreen;