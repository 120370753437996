import React, { useEffect } from 'react';
import ActivatePO from '../components/activeScreenComp/ActivatePO';

const ActivateScreen = () => {
    useEffect(() => {
        document.title = `Activate`;
    }, []);

    return (
        <div>
            <ActivatePO />            
        </div>
    );
}

export default ActivateScreen;