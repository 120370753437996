import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import useGlobal from "../../../store";

const Connection = () => {
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({ connected: false, ros: null, rosParams: undefined });

    useEffect(() => {
        init_connection();
    }, [state.rosParams])

    useEffect(() => {
        if (globalState.workers !== undefined && globalState.configWorker !== undefined) {
            var id = -1;
            for (var i = 0; i < globalState.workers.length; i++) {
                if (globalState.workers[i]._id === globalState.configWorker) {
                    id = i;
                    break;
                }
            }
            setState({ ...state, rosParams: globalState.workers[id].ros })
        }
    }, [globalState.configWorker])

    const init_connection = () => {
        if (state.rosParams !== undefined) {
            globalState.ros.on("connection", () => {
                console.log("connection established")
                globalActions.ros('connectROS', true);
            })
            console.log(state)
            globalState.ros.on("close", () => {
                console.log("connection closed")
                globalActions.ros('connectROS', false);
            })

            try {
                globalState.ros.connect(`wss://${state.rosParams.ipAddress}:${state.rosParams.port}`);
            } catch (error) {
                console.log("connection problem");
            }
        }
    }

    const renderMessage = () => {
        if(globalState.configWorker===undefined)
        { 
            return 'Select a robot to connect'
        } else if(globalState.rosConnected){
            return 'Robot Connected'
        } else {
            return 'Robot Disconnected - click here to retry'
        }
    }

    return (
        <div>
            <Alert className="text-center m-3" onClick={init_connection}
                variant={globalState.rosConnected ? "success" : "danger"}>
                {renderMessage()}
            </Alert>
        </div>
    );
}

export default Connection;