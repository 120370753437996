import React, { useEffect, useState } from 'react';
import '../style.css';
import { routings } from '../dbNames';
import useGlobal from '../../store';
import { string } from 'prop-types';
import server from '../../api/server'

const RoutingConfig = () => {
    const allowedStepUpdates = ['name', 'workstationGroup', 'workerGroup', 'tools', 'program', 'assignedCycleTimeSeconds', 'assignedDeliveryTimeSeconds', 'documentation']
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({
        status: 'Ready to configure routings',
        input: undefined
    });

    useEffect(() => {
        globalActions.getData(routings, '{}', globalState.token);
        globalActions.configureAction('configRouting', undefined)
    }, []);

    useEffect(() => {
        if (globalState.configRouting !== 'new' && globalState.configRouting !== undefined) {
            for (var i = 0; i < globalState.routings.length; i++) {
                if (globalState.routings[i]._id === globalState.configRouting) {
                    setState({ ...state, input: globalState.routings[i] });
                    break;
                }
            }
        }

    }, [globalState.configRouting])



    const selectRouting = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configRouting', event.target.id);
            setState({...state, status: 'Ready to configure routings'})
        }
    }

    const newRouting = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configRouting', event.target.id)
            const newRouting = {
                name: '**name**',
                steps: [
                    {
                        name: '**step name**',
                        workstationGroup: 'Workstation Group',
                        assignedCycleTimeSeconds: 9999,
                        assignedDeliveryTimeSeconds: 9999,
                        workerGroup: ['PO Movement'],
                        tools: ['Tool1,Tool2'],
                        program: ['Program1,Program2'],
                        documentation: 'Notes'
                    },
                    {
                        name: 'Outfeed Buffer',
                        workstationGroup: 'Outfeed Buffer',
                        assignedCycleTimeSeconds: 0,
                        assignedDeliveryTimeSeconds: 0,
                        workerGroup: ['PO Movement'],
                        tools: [''],
                        program: [''],
                        documentation: ''
                    }
                ]
            }
            setState({ ...state, input: newRouting })
        }
    }

    const insertStep = (event) => {
        if (event !== undefined) {
            var routing = state.input
            var newStep = {
                name: 'Step_number',
                workstationGroup: 'Workstation Group',
                assignedCycleTimeSeconds: 9999,
                assignedDeliveryTimeSeconds: 9999,
                workerGroup: ['PO Movement'],
                tools: ['Tool1,Tool2'],
                program: ['Program1,Program2'],
                documentation: 'Notes'
            }

            routing.steps.splice(-1, 0, newStep)
            setState({ ...state, input: routing })

        }
    }

    function renderStatus() {
        if (globalState.routings === undefined) {
            return (<p>Return to home</p>)
        } else {
            return (<p>{state.status}</p>);
        }
    }

    const submitChanges = (event) => {
        if (event !== undefined) {
            var z = document.getElementById("frm1")
            var updateObject = {}

            updateObject[z.elements[0].name] = z.elements[0].value
            updateObject['steps'] = []

            if (event.target.id === 'clone') {
                updateObject[z.elements[0].name] += ' Copy'
            }


            for (var i = 1; i < z.length; i++) {
                var nameArray = z.elements[i].name.split(".")
                updateObject['steps'][nameArray[1]] = { ...updateObject['steps'][nameArray[1]], [`${nameArray[0]}`]: z.elements[i].value }              
            }

            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            
            if (globalState.configRouting === 'new' || event.target.id === 'clone') {
                server.post(`/routings`, updateObject, config)
                    .then((response) => {
                        if (response.status === 201) {
                            setState({ ...state, status: 'Routing successfully created' });
                            globalActions.getData(routings, '{}', globalState.token);
                            globalActions.configureAction('configRouting', response.data._id)
                        } else {
                            setState({ ...state, status: `Their was an error in routing update, check data entered` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        var message = `Problem saving routing error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            } else {
                server.patch(`/routings/${globalState.configRouting}`, updateObject, config)
                    .then((response) => {
                        if (response.status === 200) {
                            setState({ ...state, status: 'Routing successfully updated' });
                            globalActions.getData(routings, '{}', globalState.token);
                        } else {
                            setState({ ...state, status: `Their was an error in routing update, check data entered` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        var message = `Problem saving routing error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            }
        }
    }

    function deleteRouting(event) {
        if (event !== undefined) {
            var confirmation = window.confirm('Are you sure you want to delete?')
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            if (confirmation === true) {
                server.delete(`/routings/${globalState.configRouting}`,config)
                    .then((response) => {
                        if (response.status === 200) {
                            setState({ ...state, status: 'Routing successfully deleted' });
                            globalActions.getData(routings, '{}', globalState.token);
                            globalActions.configureAction('configRouting', undefined)
                        } else {
                            setState({ ...state, status: `Their was an error deleting the routing` })
                        }

                    }).catch((error) => {
                        var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                        var message = `Problem deleting routing error code: ${errorCode}`
                        setState({ ...state, status: message });
                    });
            }
        }
    }

    function renderDeleteButton() {
        if (globalState.configRouting !== 'new') {
            return (
                <div>
                    <div className="button" id="save" onClick={insertStep}>
                        INSERT STEP
                </div>
                    <div className="button" id="save" onClick={submitChanges}>
                        SAVE
                </div>
                    <div className="button" id="clone" onClick={submitChanges}>
                        CLONE
                </div>
                    <div className="button" id="delete" onClick={deleteRouting}>
                        DELETE
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="button" id="save" onClick={insertStep}>
                        INSERT STEP
                    </div>
                    <div className="button" id="save" onClick={submitChanges}>
                        SAVE
                    </div>
                </div>
            )
        }
    }

    function renderRoutings() {
        if (globalState.routings === undefined) {
            return (<div>Loading....</div>)
        } else {
            return (
                <div>
                    <div className="ui message">
                        <h2>
                            Routing Configuration
                            <div className="button" id="new" onClick={newRouting}>
                                NEW
                            </div>
                        </h2>

                    </div>
                    <div className="grid">
                        <div className="quality1">
                            {globalState.routings.map(rt => {
                                var selected = "poContent"
                                if (rt._id === globalState.configRouting) {
                                    selected = "poContent selected";
                                }
                                return (
                                    <div className={selected} id={rt._id} onClick={selectRouting} >
                                        <div className="header" id={rt._id}>
                                            <i className="industry icon" id={rt._id}></i>
                                            Name: {rt.name}
                                        </div>
                                        <div className="header" id={rt._id}>
                                            <i className="qrcode icon" id={rt._id}></i>
                                            ID: {rt._id}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="editWindow">{renderEditWindow()}</div>
                    </div>
                </div>
            )
        }
    }

    function renderEditWindow() {
        if (globalState.configRouting === undefined || state.input === undefined) {
            return (<div>Please select a routing to view/delete or New to create a new routing</div>)
        } else {
            return (
                <div>
                    <div>
                        <div className="ui message">
                            <div className="header">
                                Status:
                        </div>
                            {renderStatus()}
                        </div>
                        {renderDeleteButton()}
                        <div className="spacing">_id: {globalState.configRouting}</div>
                        <form id="frm1">
                            <div className="spacing">
                                Routing Name:
                                <input
                                    className="input"
                                    type={string}
                                    name='name'
                                    value={state.input.name}
                                    onChange={e => {
                                        var routing = state.input
                                        routing.name = e.target.value
                                        setState({ ...state, input: routing })
                                    }}
                                />
                            </div>
                            {state.input.steps.map((step, index1) => {
                                return (
                                    <div className="poContent">
                                        <div className="spacing bold">Step Index: {index1}</div>
                                        {allowedStepUpdates.map((field, index) => {
                                            var type = typeof step[index]
                                            return (
                                                <div className="spacing">
                                                    {field}:                                                    
                                                        <input
                                                            className="input"
                                                            type={type}
                                                            name={`${field}.${index1}`}
                                                            value={step[field]}
                                                            onChange={e => {
                                                                var array = state.input
                                                                array.steps[index1][field] = e.target.value
                                                                setState({ ...state, input: array })
                                                            }}
                                                        />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </form>

                    </div>
                </div>
            )
        }
    }


    return (
        < div >
            {renderRoutings()}
        </div >
    )

};

export default RoutingConfig;