import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useGlobal from '../store';
import { socket } from '../api/socket'
import history from '../history';
import { settings, workers, workstations, activeManufacturing, customers, routings, partNumbers } from './dbNames';

const Menu = () => {
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        //get credentials stored locally
        var token = localStorage.getItem('token');
        if (token !== null) {
            if (globalState.token === undefined) {
                try {
                    var user = JSON.parse(localStorage.getItem('user'));
                    globalActions.loginAction('login', token);
                    globalActions.loginAction('user', user);
                } catch (e) {
                    localStorage.removeItem('user')
                    localStorage.removeItem('token')
                }
            }
        }
    }, []);

    useEffect(() => {
        // update data
        if (globalState.token !== undefined) {
            update();
        }
        if (globalState.token !== undefined && globalState.user !== undefined) {
            socket.emit('join', { userName: globalState.token, room: globalState.user._id.toString() }, (error) => {
                if (error) {
                    alert(error)
                }
            })
            console.log('check connected: ', socket.connected)

            socket.on('update', (message => {
                update()
            }))
        }
    }, [globalState.token, globalState.user])

    const update = () => {
        if (globalState.token !== undefined) {
            console.log("UPDATE MAIN MENU")
            globalActions.getData(settings, '{}', globalState.token);
            globalActions.getData(workstations, '{}', globalState.token);
            globalActions.getData(workers, '{}', globalState.token);
            globalActions.getData(activeManufacturing, '{}', globalState.token);
            globalActions.getData(customers, '{}', globalState.token);
            globalActions.getData(partNumbers, '{}', globalState.token);
            globalActions.getData(routings, '{}', globalState.token);
        }
    }

    const onSelectMenu = (event) => {
        let screenSelected = null;
        if (!event) {
            screenSelected = history.location.pathname.slice(1);
        } else {
            screenSelected = event.target.id;
        }

    }

    const renderAuto = () => {
        if (globalState.settings === undefined || globalState.workers === undefined) {
            return <div></div>
        } else {
            if (globalState.settings.autoMode === 'on') {
                return (
                    <div>
                        Auto Mode Enabled
                    </div>
                )
            } else if (globalState.settings.autoMode === 'off') {
                return (
                    <div className="smallFont">
                        Simulation Mode Enabled
                    </div>
                );
            }
        }

    }

    const renderMenu = () => {
        if (globalState.token === undefined) {
            return (
                <div className="ui primary pointing menu mainMenuBorder">
                    <Link to="/" id='home' className="item" onClick={onSelectMenu}>
                        Home
                    </Link>
                    <div className="right menu">
                        {renderLogin()}
                    </div>
                </div>
            )
        } else {

            return (
                <div className="ui primary pointing menu mainMenuBorder">
                    <Link to="/" id='home' className="item" onClick={onSelectMenu}>
                        Home
                    </Link>
                    <Link to="/ConfigurationScreen" id='configuration' className="item" onClick={onSelectMenu}>
                        Configure
                    </Link>
                    <Link to="/ActivateScreen" id='activate' className="item" onClick={onSelectMenu}>
                        Activate
                    </Link>
                    <Link to="/MonitorScreen" id='activity' className="item" onClick={onSelectMenu}>
                        Monitor
                    </Link>
                    <Link to="/ControlScreen" id='activity' className="item" onClick={onSelectMenu}>
                        Control
                    </Link>
                    <Link to="/CompletedScreen" id='completed' className="item" onClick={onSelectMenu}>
                        Completed
                    </Link>
                    <Link to="/DashboardScreen" id='dasboard' className="item" onClick={onSelectMenu}>
                        Dashboard
                    </Link>
                    <Link to="/HelpCenterScreen" id='help' className="item" onClick={onSelectMenu}>
                        Help Center
                    </Link>
                    <Link to="/SettingsScreen" id='settings' className="item" onClick={onSelectMenu}>
                        Settings
                    </Link>
                    <div className="right menu">
                        {renderAuto()}
                        {renderLogin()}
                    </div>
                </div>
            )
        }
    }



    const renderLogin = () => {
        if (globalState.token === undefined) {
            return (
                <Link to="/LoginScreen" id='login' className="item" onClick={onSelectMenu}>
                    Login
                </Link>
            );
        } else {
            return (
                <Link to="/LoginScreen" id='login' className="item" onClick={onSelectMenu}>
                    Logout
                </Link>
            );
        }
    }

    return (
        <div>
            {renderMenu()}
        </div>
    );

}

export default Menu;



