import React, { useEffect } from 'react';
import ConfigurationMenu from '../components/configurationScreenComp/ConfigurationMenu'

const ConfigurationScreen = () => {
    useEffect(() => {
        document.title = `Configure`;
    }, []);

    return (
        <div>
            <ConfigurationMenu />
        </div>
    )
}

export default ConfigurationScreen;