import React from 'react';
import { Router, Route, Switch } from 'react-router-dom'
import history from '../history';
import Menu from './Menu';
import MonitorScreen from '../productionScreens/MonitorScreen';
import ControlScreen from '../productionScreens/ControlScreen';
import ActivateScreen from '../productionScreens/ActivateScreen';
import CompletedScreen from '../productionScreens/CompletedScreen';
import DashboardScreen from '../productionScreens/DashboardScreen';
import ConfigurationScreen from '../productionScreens/ConfigurationScreen'
import NewUserScreen from '../components/userComp/NewUserScreen'
import HomeScreen from '../productionScreens/HomeScreen'
import LoginScreen from '../productionScreens/LoginScreen'
import SettingsScreen from '../productionScreens/SettingsScreen'
import HelpCenterScreen from '../productionScreens/HelpCenterScreen'

class App extends React.Component {
    render() {
        return (
            <div className="ui container">
                <Router history={history}>
                    <Menu />
                    <Switch>
                        <Route path="/" exact component={HomeScreen} />                        
                        <Route path="/ConfigurationScreen" exact component={ConfigurationScreen} />
                        <Route path="/MonitorScreen" exact component={MonitorScreen} />
                        <Route path="/ControlScreen" exact component={ControlScreen} />
                        <Route path="/ActivateScreen" exact component={ActivateScreen} />                      
                        <Route path="/CompletedScreen" exact component={CompletedScreen} />                            
                        <Route path="/DashboardScreen" exact component={DashboardScreen} /> 
                        <Route path="/NewUserScreen" exact component={NewUserScreen} /> 
                        <Route path="/LoginScreen" exact component={LoginScreen} /> 
                        <Route path="/SettingsScreen" exact component={SettingsScreen} /> 
                        <Route path="/HelpCenterScreen" exact component={HelpCenterScreen} /> 
                    </Switch>
                </Router>
            </div>
        )
    }
}

export default App;