import server from '../api/server';
import { settings, workstations, workers, partNumbers, activeManufacturing, routings, customers, decisions, completedRoutings, finishedJobs } from '../components/dbNames';

export const getData = (store, collection, query, token) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    server.get(`/getData?collection=${collection}&query=${query}`, config)
        .then((response) => {
            switch (collection) {
                case workstations:
                    store.setState({ workstations: response.data });
                    break;
                case workers:
                    store.setState({ workers: response.data });
                    break;
                case partNumbers:
                    store.setState({ partNumbers: response.data });
                    break;
                case activeManufacturing:
                    store.setState({ activeManufacturing: response.data });
                    break;
                case settings:
                    store.setState({ settings: response.data[0] });
                    break;
                case completedRoutings:
                    store.setState({ completedRoutings: response.data })
                    break;
                case routings:
                    store.setState({ routings: response.data })
                    break;
                case customers:
                    store.setState({ customers: response.data })
                    break;
                case finishedJobs:
                    store.setState({ finishedJobs: response.data })
                    break;
                case decisions:
                    store.setState({ decisions: response.data })
                    break;
                default:
                    break;
            }
        });
};

export const qaAction = (store, qaElement, value) => {
    switch (qaElement) {
        case 'qaPO':
            store.setState({ qaPO: value });
            break;
        case 'qaStep':
            store.setState({ qaStep: value });
            break;
        default:
            break;
    }
}

export const loginAction = (store, loginElement, value) => {
    switch (loginElement) {
        case 'login':
            store.setState({ token: value });
            break;
        case 'user':
            store.setState({ user: value });
            break;
        default:
            break;
    }
}

export const configureAction = (store, configureElement, value) => {
    switch (configureElement) {
        case 'configWorkstation':
            store.setState({ configWorkstation: value });
            break;
        case 'configWorker':
            store.setState({ configWorker: value });
            break;
        case 'configRouting':
            store.setState({ configRouting: value });
            break;
        case 'configCustomer':
            store.setState({ configCustomer: value });
            break;
        case 'configPartNumber':
            store.setState({ configPartNumber: value });
            break;
        case 'configActiveManufacturing':
            store.setState({ configActiveManufacturing: value });
            break;
        case 'configBuffer':
            store.setState({ configBuffer: value });
            break;
        default:
            break;
    }
}

export const ros = (store, rosElement, value) => {
    switch (rosElement) {
        case 'setROS':
            store.setState({ ros: value });
            break;
        case 'setROSParams':
            store.setState({ rosParams: value });
            break;
        case 'connectROS':
            store.setState({ rosConnected: value });
            break;
        default:
            break;
    }
}