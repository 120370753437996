import React, { useEffect, useState } from 'react';
import useGlobal from '../../store';
import server from '../../api/server';
import { PASS, REJECT, INVESTIGATION } from '../../components/statusTypes';
import { activeManufacturing } from '../../components/dbNames';

const BufferControls = () => {
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({ status: 'Select PO and step to enable controls' });

    useEffect(() => {        
        globalActions.getData(activeManufacturing, '{}', globalState.token);
    }, []);

    useEffect(() => {
        if (globalState.qaStep !== undefined && globalState.qaPO !== undefined) {
            setState(({ ...state, status: 'Perform quality actions' }))
        } else {
            setState(({ ...state, status: 'Select PO and step to enable controls' }));
        }
    }, [globalState.qaPO, globalState.qaStep]);

    function renderStatus() {
        if (globalState.activeManufacturing === undefined) {
            return (<p>Loading....</p>)
        } else {
            return (<p>{state.status}</p>);
        }
    }

    const poRemove = (event) => {
        if (event !== undefined) {
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post('/removePO', { poNum: globalState.qaPO, status: event.target.id }, config)
                .then((response) => {
                    if (response.data === 1) {
                        setState({ ...state, status: 'Success' });
                        globalActions.getData(activeManufacturing, '{}', globalState.token);
                        globalActions.qaAction('qaStep', undefined);
                        globalActions.qaAction('qaPO', undefined);
                    } else {
                        setState({ ...state, status: `Their was an error: ${response.data}` })
                    }
                });
        }
    }

    const overrideInvestigation = (event) => {
        if (event !== undefined) {
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post('/overrideInvestigation', { poNum: globalState.qaPO, stepIndex: globalState.qaStep }, config)
                .then((response) => {
                    if (response.data === 1) {
                        setState({ ...state, status: 'Success' });
                        globalActions.getData(activeManufacturing, '{}', globalState.token);
                        globalActions.qaAction('qaStep', undefined);
                        globalActions.qaAction('qaPO', undefined);
                    } else {
                        setState({ ...state, status: `Their was an error: ${response.data}` })
                    }
                });
        }
    }

    const renderPOControls = () => {
        if (globalState.qaStep !== undefined && globalState.qaPO !== undefined) {
            return (
                <div className="ui list">
                    PO Controls
                    <div className="item">
                        <button className="button" id={PASS} onClick={poRemove}>Accept & Remove</button>
                    </div>
                    <div className="item">
                        <button className="button" id={REJECT} onClick={poRemove}>Reject & Remove</button>
                    </div>
                </div>
            );
        } else {
            return (
                <div>Waiting....</div>
            );
        }
    }

    const renderStepControls = () => {
        if (globalState.qaStep !== undefined && globalState.qaPO !== undefined) {
            //we need to check if the step selected has a status of investigation
            
            for (var i = 0; i < globalState.activeManufacturing.length; i++) {
                if (globalState.qaPO === globalState.activeManufacturing[i].poNum) {
                    if (globalState.activeManufacturing[i].routing.steps[globalState.qaStep].status === INVESTIGATION) {
                        return (
                            <div>
                                Step Controls
                                < div className="item" >
                                    <button className="button" onClick={overrideInvestigation}>Override Investigation & Reintroduce</button>
                                </div >
                            </div>
                        )
                    }
                } 
            }
        } else {
            return (
                <div></div>
            );
        };
    };


    return (
        <div className="ui card">
            <div className="ui message">
                <div className="header">
                    Buffer Controls
                </div>
            </div>
            {renderPOControls()}
            {renderStepControls()}
            <div className="ui message">
                <div className="header">
                    Status:
                        </div>
                {renderStatus()}
            </div>
        </div>
    );
}

export default BufferControls;