import React, { useEffect } from 'react';
import '../components/style.css';
import ReactPlayer from 'react-player';

const HomeScreen = () => {
    useEffect(() => {
        document.title = `Home`;
    }, []);

    return (
        <div>
            <div className="openingDisplay compressed">
                <h3>Welcome to Command-Central</h3>

            </div>
            <div className="openingDisplay compressed">
                <div>
                    <h5>Simple, Powerful, Flexible</h5>
                    <div>Adaptive Goal Management for helpful robots and drones connected to the Internet</div>
                </div>
            </div>
            <div className="openingDisplay compressed">
                <div>
                    <h7>INNOVATING TO IMPROVE LIVES</h7>
                    <h5>Command Robotics Inc.</h5>
                </div>
            </div>
            <div className="openingYoutube">
                <ReactPlayer
                    className="openingYoutube"
                    url="https://youtu.be/9n2LmQtxsOE"
                    playing={true}
                    muted={true}
                />
            </div>
            <div className="openingYoutube">
                <ReactPlayer
                    className="openingYoutube"
                    url="https://youtu.be/L8LdNJhBoZg"
                />
            </div>
        </div >
    )
};

export default HomeScreen;
