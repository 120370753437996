import React, { useEffect, useState } from 'react';
import WorkstationConfig from './WorkstationConfig'
import WorkerConfig from './WorkerConfig'
import RoutingConfig from './RoutingConfig'
import PartNumberConfig from './PartNumberConfig'
import CustomerConfig from './CustomerConfig'

const ConfigurationMenu = () => {

    const [state, setState] = useState({
        screenSelected: 'Workers'
    });

    const selectScreen = (event) => {
        if (event) {
            setState({ screenSelected: event.target.id })
        }
    }

    const renderConfig = () => {
        if (state.screenSelected === 'Workstations') {
            return (
                <div>
                    <WorkstationConfig />
                </div>
            )
        } else if (state.screenSelected === 'Workers') {
            return (
                <div>
                    <WorkerConfig />
                </div>
            )
        } else if (state.screenSelected === 'PartNumbers') {
            return (
                <div>
                    <PartNumberConfig />
                </div>
            )
        } else if (state.screenSelected === 'Routings') {
            return (
                <div>
                    <RoutingConfig />
                </div>
            )
        } else if (state.screenSelected === 'Customers') {
            return (
                <div>
                    <CustomerConfig />
                </div>
            )
        } else {
            return <div>Loading....</div>
        }
    }

    return (
        <div>
            <div class="ui secondary menu mainMenuBorder">
                <a class="item" id="Workers" onClick={selectScreen}>
                    Step 1: Define Workers
                </a>
                <a class="item" id="Routings" onClick={selectScreen}>
                    Step 2: Define Manufacturing Steps
                </a>                              
                <a class="item" id="Workstations" onClick={selectScreen}>
                    Step 3: Define Workstations
                 </a>                
                <a class="item" id="Customers" onClick={selectScreen}>
                    Step 4: Setup Customers
                </a>
                <a class="item" id="PartNumbers" onClick={selectScreen}>
                    Step 5: Build Part Numbers
                </a>
            </div>
            {renderConfig()}

        </div>

    )
}



export default ConfigurationMenu;