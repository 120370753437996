import React from 'react';
import useGlobal from '../../store';

const PODetails = () => {
    const [globalState, globalActions] = useGlobal();

    const selectStep = (event) => {
        if (event !== undefined) {
            globalActions.qaAction('qaStep', event.target.id)
        }
    }

    var aM = undefined;

    for (var i = 0; i < globalState.activeManufacturing.length; i++) {
        if (globalState.activeManufacturing[i].poNum === globalState.qaPO) {
            aM = globalState.activeManufacturing[i];
            break;
        }
    }

    const renderContent = () => {
        if (aM === undefined) {
            return <div>None</div>
        } else {
            return (
                <div className="ui card">
                    <div className="content">
                        <div className="header">
                            <i className="folder icon"></i>
                            {aM.partNumber.name}
                        </div>
                        <div className="description">
                            <div>
                                Routing Template ID: {aM.partNumber.routingID}
                            </div>
                            <div>
                                Routing Template Name: {aM.routing.name}
                            </div>

                        </div>
                    </div>
                    <div class="content">
                        {aM.routing.steps.map((steps, index) => {
                            var selected = "poContent"
                            if (index === parseInt(globalState.qaStep)) {
                                selected = "poContent selected";
                            }
                            return (
                                <div className={selected} id={index} onClick={selectStep}>
                                    <i className="sync icon" id={index}></i>
                                    {steps.name}
                                    <div className="description" id={index}>Status: {steps.status}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            );
        }
    }

    return (
        <div>
            {renderContent()}
        </div>

    );
}

export default PODetails