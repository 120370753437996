import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import * as Three from 'three';
import useGlobal from '../../../store';

const RobotStatePose = () => {
    const [globalState] = useGlobal();
    const [state, setState] = useState(
        {
            x: 0,
            y: 0,
            z: 0,
            orientX: 0,
            orientY: 0,
            orientZ: 0,
            orientW: 0,
            orientDegrees: 0,
            rosParams: undefined,
            pose_subscriber: undefined
        });

    useEffect(() => {
        if (globalState.workers !== undefined && globalState.configWorker !== undefined) {
            var id = -1;
            for (var i = 0; i < globalState.workers.length; i++) {
                if (globalState.workers[i]._id === globalState.configWorker) {
                    id = i;
                    break;
                }
            }
            setState({ ...state, rosParams: globalState.workers[id].ros })
        }
        if (state.rosParams !== undefined) {
            if (state.pose_subscriber !== undefined) {
                state.pose_subscriber.unsubscribe();
            }
            setState({
                ...state, pose_subscriber: new window.ROSLIB.Topic({
                    ros: globalState.ros,
                    name: state.rosParams.poseTopic,
                    messageType: state.rosParams.poseMessageType
                }),
                x: 0,
                y: 0,
                z: 0,
                orientX: 0,
                orientY: 0,
                orientZ: 0,
                orientW: 0,
                orientDegrees: 0
            })
        }
    }, [globalState.ros, state.rosParams])

    useEffect(() => {
        getRobotState();
    }, [state.pose_subscriber])


    const getRobotState = () => {
        if (state.rosParams !== undefined) {
            //create a pose callback
            state.pose_subscriber.subscribe((message) => {
                setState({
                    ...state,
                    x: message.pose.pose.position.x.toFixed(3),
                    y: message.pose.pose.position.y.toFixed(3),
                    z: message.pose.pose.position.z.toFixed(3),
                    orientX: message.pose.pose.orientation.x.toFixed(3),
                    orientY: message.pose.pose.orientation.y.toFixed(3),
                    orientZ: message.pose.pose.orientation.z.toFixed(3),
                    orientW: message.pose.pose.orientation.w.toFixed(3),
                    orientDegrees: getOrientationFromQuaternion(message.pose.pose.orientation).toFixed(1)
                })
            })
        }
    }

    const getOrientationFromQuaternion = (ros_orientation_quaternion) => {
        var q = new Three.Quaternion(
            ros_orientation_quaternion.x,
            ros_orientation_quaternion.y,
            ros_orientation_quaternion.z,
            ros_orientation_quaternion.w
        );
        //convert this quaternion into Rool, Pitch and Yaw
        var RPY = new Three.Euler().setFromQuaternion(q);

        return RPY["_z"] * (180 / Math.PI);
    }

    return (
        <div>
            <Row>
                <Col>
                    <h4>Position</h4>
                    <p className="mt-0">x: {state.x}</p>
                    <p className="mt-0">y: {state.y}</p>
                    <p className="mt-0">Z: {state.z}</p>
                    <p className="mt-0">orientX: {state.orientX}</p>
                    <p className="mt-0">orientY: {state.orientY}</p>
                    <p className="mt-0">orientZ: {state.orientZ}</p>
                    <p className="mt-0">orientW: {state.orientW}</p>
                    <p className="mt-0">orientDegrees: {state.orientDegrees}</p>
                </Col>
            </Row>
        </div>
    );
}

export default RobotStatePose;