import React from "react";
import globalHook from "use-global-hook";

import * as actions from "../actions";

const initialState = {
    workstations: undefined,
    workers: undefined,
    routings: undefined,
    customers: undefined,
    partNumbers: undefined,
    activeManufacturing: undefined,
    completedRoutings: undefined,
    decisions: undefined,
    finishedJobs: undefined,
    qaPO: undefined,
    qaStep: undefined,
    settings: undefined,    
    configWorkstation: undefined,
    configWorker: undefined,
    configRouting: undefined,
    configCustomer: undefined,
    configPartNumber: undefined,
    configActiveManufacturing: undefined,
    configBuffer: undefined,
    token: undefined,
    user: undefined,
    ros: new window.ROSLIB.Ros(),
    rosConnected: false,
    rosParams: undefined
};

const useGlobal = globalHook(React, initialState, actions);

export default useGlobal;