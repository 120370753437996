import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useGlobal from '../store';
import { string } from 'prop-types';
import '../components/style.css';
import server from '../api/server'
import history from '../history';
import { socket } from '../api/socket';

const LoginScreen = () => {
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        document.title = 'Login'
        if (globalState.token !== undefined) {
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post(`/users/logout`, {}, config)
                .then((response) => {
                    if (response.status === 200) {
                        socket.emit('leaveRoom', globalState.user._id.toString(), (error) => {
                            console.log('disconnected')
                            if (error) {
                                alert(error)
                            }
                        })
                        globalActions.loginAction('login', undefined);
                        localStorage.removeItem('token');
                        globalActions.loginAction('user', undefined);
                        localStorage.removeItem('user');
                        setState({ ...state, status: 'Successfully logged out' });
                    } else {
                        setState({ ...state, status: `Enter credentials to login` })
                    }

                }).catch((error) => {
                    var message = `${error}`
                    setState({ ...state, status: message });
                });
        }
    }, []);

    const [state, setState] = useState({
        status: 'Enter credentials to login',
        input: { user: '', password: '' }
    });

    function renderStatus() {
        return (<p>{state.status}</p>);
    }

    function loginUser(event) {
        event.preventDefault()
        if (event !== undefined) {

            var z = document.getElementById("frm1")
            var updateObject = {}
            for (var i = 0; i < z.length; i++) {
                if (z.elements[i].value.indexOf(',') === -1) {
                    updateObject[z.elements[i].name] = z.elements[i].value
                } else {
                    updateObject[z.elements[i].name] = z.elements[i].value.split(',')
                }
            }

            try {

                if (updateObject.email === '' || updateObject.password === '') {
                    const errorMessage = { code: 403, message: 'You must enter your credentials to login' };
                    throw errorMessage;
                }

                server.post(`/users/login/`, updateObject)
                    .then((response) => {
                        if (response.status === 200) {
                            globalActions.loginAction('login', response.data.token);
                            localStorage.setItem('token', response.data.token);
                            globalActions.loginAction('user', response.data.user);
                            localStorage.setItem('user', JSON.stringify(response.data.user))
                            setState({ ...state, status: 'Successfully logged in' });
                            history.push('/');
                        } else {
                            setState({ ...state, status: `Their was an error with the username or password` })
                        }

                    }).catch((error) => {
                        var message = `Problem logging with credentials, check your email and password`
                        setState({ ...state, status: message });
                    });


            } catch (e) {
                setState({ ...state, status: e });
            }

        }
    }

    return (
        < div className="mainWindow" >
            <form id="frm1" onSubmit={loginUser}>
                <div className="spacing">
                    Email:
                    <input
                        className="input"
                        type={string}
                        name='email'
                        value={state.input.email}
                        onChange={e => {
                            var user = state.input
                            user.email = e.target.value
                            setState({ ...state, input: user })
                        }}
                    />
                </div>
                <div className="spacing">
                    Password:
                    <input
                        className="input"
                        type="password"
                        name='password'
                        value={state.input.password}
                        onChange={e => {
                            var user = state.input
                            user.password = e.target.value
                            setState({ ...state, input: user })
                        }}
                    />
                </div>
            </form>
            <div className="spacing">
                <div className="button" id="login" onClick={loginUser}>
                    SUBMIT
                </div>

            </div>

            {renderStatus()}
            <Link to="/NewUserScreen" id='newUser' className="item">
                Create New User
            </Link>
        </div >
    )

};

export default LoginScreen;