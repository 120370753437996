import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import useGlobal from '../../../store';

const RobotStateVelocity = () => {
    const [globalState] = useGlobal();
    const [state, setState] = useState(
        {
            linear_velocity_x: 0,
            linear_velocity_y: 0,
            linear_velocity_z: 0,
            angular_velocity_x: 0,
            angular_velocity_y: 0,
            angular_velocity_z: 0,
            rosParams: undefined,
            velocity_subscriber: undefined
        });

    useEffect(() => {
        if (globalState.workers !== undefined && globalState.configWorker !== undefined) {
            var id = -1;
            for (var i = 0; i < globalState.workers.length; i++) {
                if (globalState.workers[i]._id === globalState.configWorker) {
                    id = i;
                    break;
                }
            }
            setState({ ...state, rosParams: globalState.workers[id].ros })
        }
        if (state.velocity_subscriber !== undefined) {
            state.velocity_subscriber.unsubscribe();
        }
        if (state.rosParams !== undefined) {
            setState({
                ...state, velocity_subscriber: new window.ROSLIB.Topic({
                    ros: globalState.ros,
                    name: state.rosParams.velocityTopic,
                    messageType: state.rosParams.velocityMessageType
                }),
                linear_velocity_x: 0,
                linear_velocity_y: 0,
                linear_velocity_z: 0,
                angular_velocity_x: 0,
                angular_velocity_y: 0,
                angular_velocity_z: 0
            })
        }
    }, [globalState.ros, state.rosParams])

    useEffect(() => {
        getRobotState();
    }, [state.velocity_subscriber])


    const getRobotState = () => {
        if (state.rosParams !== undefined) {
            state.velocity_subscriber.subscribe((message) => {
                setState({
                    ...state,
                    linear_velocity_x: message.twist.twist.linear.x.toFixed(3),
                    linear_velocity_y: message.twist.twist.linear.y.toFixed(3),
                    linear_velocity_z: message.twist.twist.linear.z.toFixed(3),
                    angular_velocity_x: message.twist.twist.angular.x.toFixed(3),
                    angular_velocity_y: message.twist.twist.angular.y.toFixed(3),
                    angular_velocity_z: message.twist.twist.angular.z.toFixed(3)
                })
            })
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <h4>Velocities</h4>
                    <p className="mt-0">Linear Velocity X: {state.linear_velocity_x}</p>
                    <p className="mt-0">Linear Velocity Y: {state.linear_velocity_y}</p>
                    <p className="mt-0">Linear Velocity Z: {state.linear_velocity_z}</p>
                    <p className="mt-0">Angular Velocity X: {state.angular_velocity_x}</p>
                    <p className="mt-0">Angular Velocity Y: {state.angular_velocity_y}</p>
                    <p className="mt-0">Angular Velocity Z: {state.angular_velocity_z}</p>
                </Col>
            </Row>
        </div>
    );
}

export default RobotStateVelocity;