import React, { useEffect, useState } from 'react';
import '../style.css';
import {  activeManufacturing, } from '../dbNames';
import useGlobal from '../../store';
import ActiveManufacturingComponent from './ActiveManufacturingComponent';

const ActiveManufacturing = () => {
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({
        status: 'Ready to configure routings',
        input: undefined
    });

    useEffect(() => {
        update();
        globalActions.configureAction('configActiveManufacturing', undefined)
    }, []);

    const update = () => {
        globalActions.getData(activeManufacturing, '{}', globalState.token);
    }

    useEffect(() => {
        if (globalState.configRouting !== 'new' && globalState.configRouting !== undefined) {
            for (var i = 0; i < globalState.routings.length; i++) {
                if (globalState.routings[i]._id === globalState.configRouting) {
                    setState({ ...state, input: globalState.routings[i] });
                    break;
                }
            }
        }

    }, [globalState.configRouting])

    const selectAM = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configActiveManufacturing', event.target.id);
            setState({...state, status: 'Ready to configure routings'})
        }
    }

    function renderActiveManufacturing() {
        if (globalState.activeManufacturing === undefined) {
            return (<div>Loading....</div>)
        } else {
            return (
                <div>
                    <div className="grid">
                        <div className="quality1">
                            <div className="ui message">
                                <h4>
                                    Active Manufacturing
                                </h4>
                            </div>
                            {globalState.activeManufacturing.map(aM => {
                                var selected = "poContent"
                                if (aM._id === globalState.configActiveManufacturing) {
                                    selected = "poContent selected";
                                }
                                return (
                                    <div className={selected} id={aM._id} onClick={selectAM} >
                                        <div className="header" id={aM._id}>
                                            <i className="industry icon" id={aM._id}></i>
                                            PO #: {aM.poNum}
                                            <div id={aM._id} onClick={selectAM}>Status: {aM.status}</div>
                                            <div id={aM._id} onClick={selectAM}>Location: {aM.partLocation}</div>
                                        </div>                                        
                                    </div>
                                )
                            })}
                        </div>
                        <div className="editWindow">{renderDetailsWindow()}</div>
                    </div>
                </div>
            )
        }
    }

    function renderDetailsWindow() {
        if (globalState.configActiveManufacturing === undefined) {
            return (<div>Please select a routing to view/delete or New to create a new routing</div>)
        } else {
            var aM = 0;
            for (var i = 0; i < globalState.activeManufacturing.length; i++) {
                if (globalState.activeManufacturing[i]._id === globalState.configActiveManufacturing) {
                    aM = i;
                    break;
                }
            }
            return (
                <div>
                    <ActiveManufacturingComponent id={aM} />
                </div>
            )
        }
    }
    
    return (
        < div >
            {renderActiveManufacturing()}
        </div >
    )
};

export default ActiveManufacturing;