import React, { useEffect, useState } from 'react';
import useGlobal from '../store';
import { outfeedBuffer, activeManufacturing } from '../components/dbNames';
import { ACTIVE } from '../components/statusTypes';
import '../components/style.css';
import StepDetails from '../components/CompletedScreenComp/StepDetails';
import PODetails from '../components/CompletedScreenComp/PODetails';
import BufferControls from '../components/CompletedScreenComp/BufferControls';

const CompletedScreen = () => {
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({ jobsInBuffer: [] });

    useEffect(() => {
        document.title = 'Completed';
        if (globalState.token !== undefined) {
            globalActions.getData(activeManufacturing, '{}', globalState.token);
        }
        globalActions.qaAction('qaPO', undefined)
    }, []);

    useEffect(() => {
        var jobs = [];
        if (globalState.activeManufacturing !== undefined && globalState.workstations !== undefined) {
            for (var i = 0; i < globalState.activeManufacturing.length; i++) {
                for (var j = 0; j < globalState.workstations.length; j++) {
                    if (globalState.workstations[j].name === globalState.activeManufacturing[i].partLocation) {
                        if (globalState.workstations[j].workstationGroup.includes(outfeedBuffer) && globalState.activeManufacturing[i].status === ACTIVE) {
                            jobs.push(globalState.activeManufacturing[i])
                        }
                    }                    
                }                
            }
        }
        setState({ ...state, jobsInBuffer: jobs });
    }, [globalState.activeManufacturing, globalState.qaPO, globalState.workstations]);

    const selectPO = (event) => {
        if (event !== undefined) {
            globalActions.qaAction('qaPO', event.target.id);
            globalActions.qaAction('qaStep', undefined)
        }
    }

    function renderCompletedActions() {
        if (globalState.activeManufacturing === undefined) {
            return (<div>Loading...</div>)
        } else {
            
            return (
                <div>
                    <div className="ui message">
                        <h2>Manufacturing Jobs in Outfeed Buffer ready for Quality Review</h2>
                    </div>
                    <div className="grid">
                        <div className="quality1">
                            {state.jobsInBuffer.map(aM => {
                                var selected = "poContent"
                                if (aM.poNum === globalState.qaPO) {
                                    selected = "poContent selected";
                                }
                                return (
                                    <div class={selected} id={aM.poNum} onClick={selectPO}>
                                        <div className="header" id={aM.poNum}>
                                            <i className="barcode icon" id={aM.poNum}></i>
                                            PO #: {aM.poNum}
                                        </div>
                                        <div className="header" id={aM.poNum}>
                                            <i className="qrcode icon" id={aM.poNum}></i>
                                            Serial #: {aM.partSerialNum}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="quality2">
                            <PODetails />
                        </div>
                        <div className="quality3">
                            <div className="ui card">
                                <StepDetails id={globalState.qaStep} />
                            </div>
                        </div>
                        <div className="quality4">
                            <div className="ui card">
                                <BufferControls />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    return <div>{renderCompletedActions()}</div>
}

export default CompletedScreen;