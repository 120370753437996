import React, { useEffect, useState } from 'react';
import useGlobal from "../../store";
import server from '../../api/server';
import { WAITING, ACTIVE, RESERVED } from '../../components/statusTypes';
import { workers } from '../../components/dbNames';
import '../../components/style.css';

const WorkerScreen = props => {
    const [globalState, globalActions] = useGlobal();
    const [state, setState] = useState({
        status: 'Worker controls ready',
    });

    useEffect(() => {
        globalActions.configureAction('configWorker', undefined);
        globalActions.configureAction('configWorkstation', undefined);
        update();
    }, []);

    const update = () => {
        globalActions.getData(workers, '{}', globalState.token);
    }

    const selectWorker = (event) => {
        if (event !== undefined) {
            globalActions.configureAction('configWorker', event.target.id);
            globalActions.configureAction('configWorkstation', undefined);
        }
    }

    function setWorkerEmpty(event) {
        if (event !== undefined) {
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post('/setWorkerEmpty', { name: event.target.id }, config)
                .then((response) => {
                    update();
                });
        }
    }

    const getNextJob = (event) => {
        if (event !== undefined) {
            server.get(`/workerGetNextJob?key=${event.target.id}&owner=${event.target.dataset.owner}`)
                .then((response) => {
                    if (response.data.status === '1') {
                        setState({ ...state, status: 'Worker has a new job, activate job' })
                    } else {
                        setState({ ...state, status: `${response.data.status}: ${response.data.name}` })
                    }
                }).catch((error) => {
                    var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                    setState({ ...state, status: errorCode });
                });
        }
    }

    const activateJob = (event) => {
        if (event !== undefined) {
            server.get(`/workerActivateJob?key=${event.target.id}`)
                .then((response) => {
                    if (response.data.status === '1') {
                        setState({ ...state, status: 'Job activated, move to source' })
                    } else {
                        setState({ ...state, status: `${response.data.status}: ${response.data.name}` })
                    }
                }).catch((error) => {
                    var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                    setState({ ...state, status: errorCode });
                });
        }
    }

    const moveWorkerSource = (event) => {
        if (event !== undefined) {
            server.get(`/workerLocation?key=${event.target.id}&workerLocation=source`)
                .then((response) => {
                    if (response.data.status === '1') {
                        setState({ ...state, status: 'Worker at source, take part' })
                    } else {
                        setState({ ...state, status: `${response.data.status}: ${response.data.name}` })
                    }
                }).catch((error) => {
                    var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                    setState({ ...state, status: errorCode });
                });
        }
    }

    const moveWorkerDest = (event) => {
        if (event !== undefined) {
            server.get(`/workerLocation?key=${event.target.id}&workerLocation=dest`)
                .then((response) => {
                    if (response.data.status === '1') {
                        setState({ ...state, status: 'Worker at destination, load workstation' })
                    } else {
                        setState({ ...state, status: `${response.data.status}: ${response.data.name}` })
                    }
                }).catch((error) => {
                    var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                    setState({ ...state, status: errorCode });
                });
        }
    }

    const takePart = (event) => {
        if (event !== undefined) {
            server.get(`/workerTakePart?key=${event.target.id}`)
                .then((response) => {
                    if (response.data.status === '1') {
                        setState({ ...state, status: 'Worker has part, move to destination' })
                    } else {
                        setState({ ...state, status: `${response.data.status}: ${response.data.name}` })
                    }
                }).catch((error) => {
                    var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))           
                    setState({ ...state, status: errorCode });
                });
        }
    }

    const loadWorkstation = (event) => {
        if (event !== undefined) {
            server.get(`/workerLoadPart?key=${event.target.id}`)
                .then((response) => {
                    if (response.data.status === '1') {
                        setState({ ...state, status: 'Workstation loaded, archive job' })
                    } else {
                        setState({ ...state, status: `${response.data.status}: ${response.data.name}` })
                    }
                }).catch((error) => {
                    var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                    setState({ ...state, status: errorCode });
                });
        }
    }

    const archiveJob = (event) => {
        if (event !== undefined) {
            server.get(`/workerArchiveJob?key=${event.target.id}`)
                .then((response) => {
                    if (response.data.status === '1') {
                        setState({ ...state, status: 'Worker controls ready' })
                    } else {
                        setState({ ...state, status: `${response.data.status}: ${response.data.name}` })
                    }
                }).catch((error) => {
                    var errorCode = parseInt(error.message.slice(error.message.indexOf("code") + 5, error.message.length))
                    setState({ ...state, status: errorCode });
                });
        }
    }

    function setOnlineOffine(event) {
        if (event !== undefined) {
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post('/setWorkerOnlineOffline', { name: event.target.id }, config)
                .then((response) => {
                    update();
                });
        }
    }

    function renderStatus() {
        if (globalState.customers === undefined) {
            return (<p>Return to home</p>)
        } else {
            return (<p>{state.status}</p>);
        }
    }

    function renderSimulation(id) {
        if (globalState.settings === undefined) {
            return <div></div>
        } else if (globalState.settings.autoMode === 'off') {
            return (
                <div className="ui link list p12">
                    <h5>Simulation Controls</h5>
                    <div className="item">
                        <div className="button" id={globalState.workers[id]._id} data-owner={globalState.workers[id].owner} onClick={getNextJob}>1. Find Next Job</div>
                        <div className="button" id={globalState.workers[id]._id} onClick={activateJob}>2. Activate Job</div>
                    </div>
                    <div className="item">
                        <div className="button" id={globalState.workers[id]._id} onClick={moveWorkerSource}>3. Move Worker Source</div>
                        <div className="button" id={globalState.workers[id]._id} onClick={takePart}>4. Take Part</div>
                    </div>
                    <div className="item">
                        <div className="button" id={globalState.workers[id]._id} onClick={moveWorkerDest}>5. Move Worker Destination</div>
                        <div className="button" id={globalState.workers[id]._id} onClick={loadWorkstation}>6. Load Workstation</div>
                    </div>
                    <div className="item">
                        <div className="button" id={globalState.workers[id]._id} onClick={archiveJob}>7. Archive Job</div>
                    </div>
                </div>
            )
        } else {
            return <div></div>
        }
    }

    function renderWorkerDetails() {
        if (globalState.workers !== undefined && globalState.configWorker !== undefined) {
            var id = -1;
            for (var i = 0; i < globalState.workers.length; i++) {
                if (globalState.workers[i]._id === globalState.configWorker) {
                    id = i;
                    break;
                }
            }
            return (
                <div className="editWindow1">
                    <h4>{globalState.workers[id].name}</h4>
                    <div className="grid">
                        <div className="ui link list p11">
                            <h5>Worker Controls</h5>
                            <div className="item">
                                <button className="button" id={globalState.workers[id].name} onClick={setWorkerEmpty}>Set Worker empty</button>
                            </div>
                            <div className="item">
                                <button className="button" id={globalState.workers[id].name} onClick={setOnlineOffine}>Set ONLINE/OFFLINE</button>
                            </div>
                            
                        </div>
                        {renderSimulation(id)}
                                             
                    </div>
                    <div className="ui message smallerStatus">
                        <div className="header">
                            Status:
                                </div>
                        {renderStatus()}
                    </div>   
                    <div className="grid">
                        <div className="workstationDescription1">
                            <h5>
                                Status: {globalState.workers[id].status}
                            </h5>
                            <h5>Worker Groups:</h5>
                            <div className="ui bulleted list">
                                {globalState.workers[id].workerGroup.map(workerGroup => (
                                    <div className="item">
                                        {workerGroup}
                                    </div>
                                ))}
                            </div>

                            <h5>Grid Location:</h5>
                            <div className="ui bulleted list">
                                <div className="item">
                                    posX: {globalState.workers[id].gridLocation.posX}
                                </div>
                                <div className="item">
                                    posY: {globalState.workers[id].gridLocation.posY}
                                </div>
                                <div className="item">
                                    posZ: {globalState.workers[id].gridLocation.posZ}
                                </div>

                                <div className="item">
                                    orentX: {globalState.workers[id].gridLocation.orientX}
                                </div>
                                <div className="item">
                                    orentY: {globalState.workers[id].gridLocation.orientY}
                                </div>
                                <div className="item">
                                    orentZ: {globalState.workers[id].gridLocation.orientZ}
                                </div>
                                <div className="item">
                                    orentW: {globalState.workers[id].gridLocation.orientW}
                                </div>
                            </div>
                        </div>

                        <div className="workstationDescription2">
                            <h5>Job Queue</h5>
                            <div>PO: {globalState.workers[id].jobQueue[0].po}</div>
                            <div>Source: {globalState.workers[id].jobQueue[0].source}</div>
                            <div>Destination: {globalState.workers[id].jobQueue[0].destination}</div>
                            <div>
                                Step # (start is index 0): {globalState.workers[id].jobQueue[0].stepIndex}
                            </div>
                            <h5>Job Active</h5>
                            <div>PO: {globalState.workers[id].jobActive[0].po}</div>
                            <div>Source: {globalState.workers[id].jobActive[0].source}</div>
                            <div>Destination: {globalState.workers[id].jobActive[0].destination}</div>
                            <div>Step # (start is index 0): {globalState.workers[id].jobActive[0].stepIndex}</div>
                        </div>
                        
                    </div>
                </div>
            );
        } else {            
            return <div>Select a worker to view details</div>
        }
    }

    function renderWorker() {
        if (globalState.workers === undefined) {
            return (<div>Loading....</div>)
        } else {
            return (
                <div>
                    <div className="grid">
                        <div className="quality1">
                            <div className="ui message">
                                <h4>
                                    Worker Activity
                                </h4>
                            </div>
                            {globalState.workers.map((wk, index) => {                                

                                var selected = "poContent"

                                let icon = 'exclamation triangle icon'
                                switch (wk.status) {
                                    case WAITING:
                                        if (wk.jobQueue[0].po !== '') {
                                            selected = "poContent active"
                                        } else {
                                            selected = "poContent"
                                        }
                                        icon = "heart outline icon";
                                        break;
                                    case ACTIVE:
                                        selected = "poContent active"
                                        icon = "cogs icon";
                                        break;
                                    case RESERVED:
                                        selected = "poContent reserved"
                                        icon = "download icon";
                                        break;
                                    default:
                                        selected = "poContent error"
                                        icon = "exclamation triangle icon";
                                        break;
                                }

                                if (globalState.configWorker === wk._id) {
                                    selected += " selected"
                                }

                                return (
                                    <div className={selected} id={wk._id} onClick={selectWorker} >
                                        <div className="header" id={wk._id} onClick={selectWorker}>
                                            <i className={icon}></i>
                                            WS Name: {wk.name}
                                            <div id={wk._id} onClick={selectWorker} >PO in Station: {wk.poInStation[0]}</div>
                                            <div id={wk._id} onClick={selectWorker} >Source: {wk.jobActive[0].source} | Destination: {wk.jobActive[0].destination}</div>
                                        </div>

                                    </div>
                                )
                            })}


                        </div>
                        <div className="editWindow">{renderWorkerDetails()}</div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div>
            {renderWorker()}
        </div>
    );
}

export default WorkerScreen;