import React, { useEffect } from 'react';
import useGlobal from '../store';
import { completedRoutings, decisions, finishedJobs, settings } from '../components/dbNames';
import '../components/style.css';
import server from '../api/server'
import { formatStroop } from '../components/utils'

const ProductionAnalysisScreen = () => {
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        document.title = 'Dashboard'
        if (globalState.token !== undefined) {
            globalActions.getData(completedRoutings, '{}', globalState.token);
            globalActions.getData(decisions, '{}', globalState.token);
            globalActions.getData(finishedJobs, '{}', globalState.token);
            globalActions.getData(settings, '{}', globalState.token);
        }
        globalActions.qaAction('qaPO', undefined)
        globalActions.qaAction('qaStep', undefined)
    }, []);

    useEffect(() => {
        if (globalState.token !== undefined) {
            globalActions.getData(completedRoutings, '{}', globalState.token);
            globalActions.getData(decisions, '{}', globalState.token);
            globalActions.getData(finishedJobs, '{}', globalState.token);
            globalActions.getData(settings, '{}', globalState.token);
        }
    }, [globalState.token, globalState.workers])

    const selectPO = (event) => {
        if (event !== undefined) {
            globalActions.qaAction('qaPO', event.target.id);
        }
    }

    function deleteAllCompletedRecords(event) {
        if (event !== undefined) {
            var confirmation = window.confirm('Are you sure you want to delete?')
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            if (confirmation === true) {
                server.delete(`/completedRoutings`, config)
                    .then((response) => {
                        if (response.status === 200) {
                            globalActions.getData(completedRoutings, '{}', globalState.token)
                            globalActions.qaAction('qaPO', undefined)
                        } else {
                            console.log('bad')
                        }

                    }).catch((error) => {
                        console.log('more problems')
                    });
            }
        }
    }

    function renderProductionDetails() {
        if (globalState.qaPO === undefined) {
            return <div>Select PO</div>
        } else {
            var routing = {};
            for (var i = 0; i < globalState.completedRoutings.length; i++) {
                if (globalState.completedRoutings[i].poNum === globalState.qaPO) {
                    routing = globalState.completedRoutings[i]
                    break;
                }
            }
            if (Object.getOwnPropertyNames(routing).length === 0) {
                // nothing is selected
                return <div>Select PO</div>
            } else {
                return (
                    <div>
                        Total Production Time: {(routing.routing.steps[routing.routing.steps.length - 2].kpv.dateTimeEnd - routing.routing.steps[0].kpv.dateTimeStart) / 1000 / 60} minutes
                    </div>
                )
            }
        }
    }

    function renderTaktTime() {
        var finalTimes = [];
        for (var i = 0; i < globalState.completedRoutings.length; i++) {
            finalTimes.push(globalState.completedRoutings[i].routing.steps[globalState.completedRoutings[i].routing.steps.length - 2].kpv.dateTimeEnd);
        }
        finalTimes.sort()
        var meanTaktTime = 0
        for (var j = 1; j < finalTimes.length; j++) {
            meanTaktTime = meanTaktTime + (finalTimes[j] - finalTimes[j - 1])
        }

        meanTaktTime = Math.round(100 * meanTaktTime / (finalTimes.length - 1) / 1000 / 60) / 100;

        if (meanTaktTime === undefined || isNaN(meanTaktTime)) {
            meanTaktTime = 0;
        }

        var yearlyOutput = Math.round(100 * 0.85 * 2000 * 60 / meanTaktTime) / 100;

        if (Math.abs(yearlyOutput) === Infinity || isNaN(yearlyOutput)) {
            yearlyOutput = 0;
        }

        var finishedJobs = 0;
        if (globalState.finishedJobs !== undefined) {
            finishedJobs = globalState.finishedJobs.length;
        }

        var decisions = 0;
        if (globalState.decisions !== undefined) {
            decisions = globalState.decisions.length;
        }

        //blockchain stats

        //completed routings
        var totalActualWorkstationCost = 0;
        var totalWorkstationCost = 0;
        var totalActualDeliveryCost = 0;
        var totalDeliveryCost = 0;
        var actualFees = 0;
        var actualNetworkCosts = 0
        for (i = 0; i < globalState.completedRoutings.length; i++) {
            totalActualWorkstationCost += globalState.completedRoutings[i].actualWorkstationCost;
            totalWorkstationCost += globalState.completedRoutings[i].totalWorkstationCost;
            totalActualDeliveryCost += globalState.completedRoutings[i].actualWorkerCost;
            totalDeliveryCost += globalState.completedRoutings[i].totalDeliveryCost;
            actualFees += globalState.completedRoutings[i].actualFees;
            actualNetworkCosts += globalState.completedRoutings[i].actualNetworkCosts
        }

        //active routings
        var activeActualWorkstationCost = 0;
        var activeWorkstationCost = 0;
        var activeActualDeliveryCost = 0;
        var activeDeliveryCost = 0;
        var activeActualFees = 0;
        var activeActualNetworkCosts = 0
        for (i = 0; i < globalState.activeManufacturing.length; i++) {
            activeActualWorkstationCost += globalState.activeManufacturing[i].actualWorkstationCost;
            activeWorkstationCost += globalState.activeManufacturing[i].totalWorkstationCost;
            activeActualDeliveryCost += globalState.activeManufacturing[i].actualWorkerCost;
            activeDeliveryCost += globalState.activeManufacturing[i].totalDeliveryCost;
            activeActualFees += globalState.activeManufacturing[i].actualFees;
            activeActualNetworkCosts += globalState.activeManufacturing[i].actualNetworkCosts
        }
        var routingFunds = globalState.activeManufacturing.length * 5;
        var currency = 'Lumens';
        var currencySymbol = '';
        if(globalState.settings.currencyAssetCode !== 'native') {
            currency = globalState.settings.currencyAssetCode;
            currencySymbol = '$'
        }
        return (
            <div>
                <div className="grid">
                    <h4>KPIs</h4>
                    <div className="editWindow">
                        <div>Average Takt Time: {meanTaktTime} minutes for a batch of {finalTimes.length} parts</div>
                        <div>Estimated one shift yearly part output of {yearlyOutput} with 85% availability</div>
                        <div>Number of jobs completed by your workers: {finishedJobs}</div>
                        <div>Number of decisions made to keep your workers busy: {decisions}</div>
                    </div>
                    <h4>Completed Work</h4>
                    <div className="editWindow">
                        <h5>Workers</h5>
                        <div>Forecasted Worker Expenses: {currencySymbol}{formatStroop(totalDeliveryCost)} {currency}</div>
                        <div>Realized Worker Expenses: {currencySymbol}{formatStroop(totalActualDeliveryCost)} {currency}</div>
                        <h5>Workstations</h5>
                        <div>Forecasted Workstation Expenses: {currencySymbol}{formatStroop(totalWorkstationCost)} {currency}</div>
                        <div>Realized Workstation Expenses: {currencySymbol}{formatStroop(totalActualWorkstationCost)} {currency}</div>
                        <h5>Network</h5>
                        <div>Realized Network Expenses: {currencySymbol}{formatStroop(actualNetworkCosts)} {currency}</div>                        
                        <h5>Spending</h5>
                        <div>Total spend: {currencySymbol}{formatStroop(totalActualDeliveryCost + totalActualWorkstationCost + actualNetworkCosts)} {currency}</div>
                        <div>Amount Refunded: {currencySymbol}{formatStroop((totalDeliveryCost + totalWorkstationCost) - (totalActualDeliveryCost + totalActualWorkstationCost))} {currency}</div>
                        <h5>Fees</h5>
                        <div>Fees paid by the network: {formatStroop(actualFees)} Lumens</div>
                        <div>****************</div>
                    </div>
                    <h4>Work In Progress</h4>
                    <div className="editWindow">
                        <h5>Workers</h5>
                        <div>Forecasted Worker Expenses: {currencySymbol}{formatStroop(activeDeliveryCost)} {currency}</div>
                        <div>Realized Worker Expenses: {currencySymbol}{formatStroop(activeActualDeliveryCost)} {currency}</div>
                        <h5>Workstations</h5>
                        <div>Forecasted Workstation Expenses: {currencySymbol}{formatStroop(activeWorkstationCost)} {currency}</div>
                        <div>Realized Workstation Expenses: {currencySymbol}{formatStroop(activeActualWorkstationCost)} {currency}</div>
                        <h5>Network</h5>
                        <div>Realized Network Expenses: {currencySymbol}{formatStroop(activeActualNetworkCosts)} {currency}</div>                        
                        <h5>Spending</h5>
                        <div>Total spend: {currencySymbol}{formatStroop(activeActualDeliveryCost + activeActualWorkstationCost + activeActualNetworkCosts)} {currency}</div>
                        <div>Forecasted spending: {currencySymbol}{formatStroop((activeDeliveryCost + activeWorkstationCost) - (activeActualDeliveryCost + activeActualWorkstationCost))} {currency}</div>
                        <div>Funds held for minimum balances: {formatStroop(routingFunds)} Lumens</div>
                        <h5>Fees</h5>
                        <div>Fees paid by the network: {formatStroop(activeActualFees)} Lumens</div>
                    </div>
                </div>
            </div>
        )
    }

    function renderProductionAnalysisActions() {
        if (globalState.completedRoutings === undefined || globalState.activeManufacturing === undefined || globalState.settings === undefined ){
            return (<div>Loading...</div>)
        } else {
            return (
                <div>
                    <div className="ui message">
                        <h2>Production Dashboard

                        </h2>
                        <div>{renderTaktTime()}</div>
                        <div className="ui button" id="delete" onClick={deleteAllCompletedRecords}>
                            DELETE ALL RECORDS
                        </div>
                    </div>
                    <div className="grid">
                        <div className="quality1">
                            {globalState.completedRoutings.map(cM => {
                                var selected = "poContent"
                                if (cM.poNum === globalState.qaPO) {
                                    selected = "poContent selected";
                                }
                                return (
                                    <div class={selected} id={cM.poNum} onClick={selectPO}>
                                        <div className="header" id={cM.poNum}>
                                            <i className="barcode icon" id={cM.poNum}></i>
                                            PO #: {cM.poNum}
                                        </div>
                                        <div className="header" id={cM.poNum}>
                                            <i className="qrcode icon" id={cM.poNum}></i>
                                            Serial #: {cM.partSerialNum}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="quality2">
                            <div>{renderProductionDetails()}</div>
                        </div>
                    </div>
                </div>

            );
        }
    }
    return <div>{renderProductionAnalysisActions()}</div>
}

export default ProductionAnalysisScreen;