import React, { useEffect, useState } from 'react';
import useGlobal from '../store';
import '../components/style.css';
import server from '../api/server'
import { settings } from '../components/dbNames';

const SettingsScreen = () => {
    const [globalState, globalActions] = useGlobal();

    const [state, setState] = useState({
        status: 'Ready to configure settings',
        monitorGrid: { startX: 0, startY: 1, endX: 2, endY: 3 },
        allowSave: false
    });

    useEffect(() => {
        document.title = 'Settings'
        globalActions.getData(settings, '{}', globalState.token);
    }, []);

    useEffect(() => {
        if (globalState.settings !== undefined) {
            setState({ ...state, monitorGrid: globalState.settings.monitorGrid })
        }
    }, [globalState.settings]);

    const activateAutoMode = (event) => {
        if (event !== undefined) {
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post(`/toggleAutoMode`, {}, config)
                .then((response) => {
                    globalActions.getData(settings, '{}', globalState.token);
                });
        }
    }

    const submitChanges = (event) => {
        if (event !== undefined) {
            //check to make sure that monitor grid values start < end
            console.log(state.monitorGrid)
            if (state.monitorGrid.startX > state.monitorGrid.endX || state.monitorGrid.startY > state.monitorGrid.endY) {
                setState({ ...state, status: 'Start values must be less than end values for the Monitor Screen Grid Settings', allowSave: false })
            } else {
                //go ahead and save
                const config = {
                    headers: { Authorization: `Bearer ${globalState.token}` }
                };
                server.patch(`/saveMonitorGrid`, state.monitorGrid, config)
                    .then((response) => {
                        globalActions.getData(settings, '{}', globalState.token);
                        setState({ ...state, allowSave: false, status: 'Settings have been saved' })
                    });
            }
        }
    }

    const activateWorkstationCycle = (event) => {
        if (event !== undefined) {
            const config = {
                headers: { Authorization: `Bearer ${globalState.token}` }
            };
            server.post(`/toggleWorkstationCycle`, {}, config)
                .then((response) => {
                    globalActions.getData(settings, '{}', globalState.token);
                });
        }
    }

    const renderAutoButton = () => {
        if (globalState.settings === undefined) {
            return <div></div>
        } else {
            if (globalState.settings.autoMode === 'on') {
                return (
                    <div className="spacing">
                        Enable control of worker jobs:
                        <div className="button" id="autoMode" onClick={activateAutoMode}>Simulation Mode</div>
                    </div>
                )
            } else if (globalState.settings.autoMode === 'off') {
                return (
                    <div className="spacing">
                        Disable control of worker jobs:
                        <div className="button" id="autoMode" onClick={activateAutoMode}>Auto Mode</div>
                    </div>
                );
            }
        }
    }

    const renderCycleWorkstationButton = () => {
        if (globalState.settings === undefined) {
            return <div></div>
        } else {
            if (globalState.settings.cycleWorkstations) {
                return (
                    <div className="spacing">
                        Simulation of workstation cycles:
                        <div className="button" id="cycleWorkstations" onClick={activateWorkstationCycle}>Turn off workstation auto cycling</div>
                    </div>
                )
            } else {
                return (
                    <div className="spacing">
                        Simulation of workstation cycles:
                        <div className="button" id="cycleWorkstations" onClick={activateWorkstationCycle}>Enable workstation auto cycling</div>
                    </div>
                );
            }
        }
    }

    const sizeToWorkstation = (event) => {
        if (event !== undefined) {
            if (globalState.workstations === undefined) {
                setState({ ...state, status: 'No workstations are loaded' })
            } else if (globalState.workstations.length === 0) {
                setState({ ...state, status: 'There are no workstations configured' })
            } else {
                let workstations = globalState.workstations
                var minX, minY, maxX, maxY;
                for (let i = 0; i < workstations.length; i++) {
                    if (i === 0) {
                        minX = workstations[i].gridLocation.posX;
                        maxX = workstations[i].gridLocation.posX;
                        minY = workstations[i].gridLocation.posY;
                        maxY = workstations[i].gridLocation.posY;
                    } else {
                        if (minX > workstations[i].gridLocation.posX) {
                            minX = workstations[i].gridLocation.posX;
                        }
                        if (maxX < workstations[i].gridLocation.posX) {
                            maxX = workstations[i].gridLocation.posX;
                        }
                        if (minY > workstations[i].gridLocation.posY) {
                            minY = workstations[i].gridLocation.posY;
                        }
                        if (maxY < workstations[i].gridLocation.posY) {
                            maxY = workstations[i].gridLocation.posY;
                        }
                    }
                }
                minX -= 1;
                minY -= 1;
                maxX += 1;
                maxY += 1;
                setState({
                    ...state,
                    monitorGrid: { startX: minX, startY: minY, endX: maxX, endY: maxY },
                    status: 'Press Save button to save changes',
                    allowSave: true
                })
            }
        }
    }

    const renderEditMonitorGrid = () => {
        if (globalState.settings === undefined) {
            return (<div>Loading....</div>)
        } else {
            return (
                <div>
                    <div className="spacing">Monitor Screen Grid Settings:</div>
                    {Object.keys(state.monitorGrid).map(function (key, index) {
                        return (
                            <div className="spacing">
                                {key}:<input
                                    className="input small"
                                    type={typeof state.monitorGrid[key]}
                                    name={key}
                                    value={state.monitorGrid[key]}
                                    onChange={e => {
                                        var monitorGrid = state.monitorGrid
                                        //here we need to make sure that the start is less than the end
                                        var status = 'Press Save button to save changes'
                                        monitorGrid[key] = parseFloat(e.target.value)
                                        setState({ ...state, monitorGrid, status, allowSave: true })
                                    }}
                                />
                            </div>
                        )
                    })}
                    <div className="button" id="save" onClick={sizeToWorkstation}>
                        Size to Workstation Coordinates
                    </div>
                    {renderSaveButton()}
                </div>
            )
        }
    }

    const renderNetworkQualities = () => {
        if (globalState.settings === undefined || globalState.user === undefined) {
            return (<div>Loading....</div>)
        } else {
            var currency = 'Lumens';
            if(globalState.settings.currencyAssetCode !== 'native') {
                currency = globalState.settings.currencyAssetCode;
            }
            return (                
                <div>
                    <div className="spacing">Stellar Network: {globalState.settings.stellarNetwork}</div>
                    <div className="spacing">Currency Asset Code: {globalState.settings.currencyAssetCode} </div>
                    <div className="spacing">Currency Issuer: {globalState.settings.currencyIssuerPublicKey}</div>
                    <div className="spacing">User Public Key: {globalState.user.publicKey}</div>
                    <div className="spacing">Network Public Key: {globalState.settings.agmPublicKey}</div>
                    <div className="spacing">Network Fee: {globalState.settings.networkFee} {currency}</div>
                </div >
            )
        }
    }

    function renderSaveButton() {
        if (state.allowSave) {
            return (
                <div className="button" id="save" onClick={submitChanges}>
                    SAVE
                </div>
            )
        } else {
            return (<div></div>)
        }
    }

    function renderStatus() {
        if (globalState.settings === undefined) {
            return (<p>Return to home</p>)
        } else {
            return (<p>{state.status}</p>);
        }
    }
    return (
        <div>
            <div className="ui message">
                <h2>
                    Settings Menu
                </h2>
            </div>
            {renderNetworkQualities()}
            {renderAutoButton()}
            {renderCycleWorkstationButton()}
            {renderEditMonitorGrid()}
            <div className="ui message">
                <div className="header">
                    Status:
                </div>
                {renderStatus()}
            </div>
        </div>
    )

};

export default SettingsScreen;