//database
const productionDb = 'agm';
const developmentDb = "agm-test"
export const database = productionDb;

//connection string
const production = 'https://adaptive-goal-management.herokuapp.com/';
const development = 'http://127.0.0.1:3001/';
export const connectionString = production;

//collections
export const activeManufacturing = 'activeroutings';
export const routingTemplates = 'routings';
export const workstations = 'workstations';
export const workers = 'workers';
export const decisions = 'decisions';
export const finishedJobs = 'finishedjobs';
export const partNumbers = 'partnumbers';
export const settings = 'settings';
export const completedRoutings = 'completedroutings';
export const routings = 'routings';
export const customers = 'customers';

//workstations
export const infeedBuffer = 'Infeed Buffer';
export const outfeedBuffer = 'Outfeed Buffer';
export const unknown = 'unknown';

// workers
export const robot1 = 'Tesla';
